import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing }: Theme) {
  return createStyles({
    root: {
      width: '100%',
      paddingTop: spacing(6),
    },
  });
}
