import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Flex, { FlexProps } from '@shared/components/Flex';

import styles from './AuthFormFooter.styles';

export interface AuthFormFooterProps
  extends WithStyles<typeof styles>,
    Omit<FlexProps, 'classes'> {}

const AuthFormFooter: React.FC<AuthFormFooterProps> = ({ classes, justify, ...otherProps }) => {
  return <Flex justify={justify} classes={{ root: classes.root }} {...otherProps} />;
};

AuthFormFooter.defaultProps = {
  justify: 'flex-start',
};

export default withStyles(styles)(AuthFormFooter);
