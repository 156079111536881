import Axios from 'axios';
import { injectable } from 'inversify';

import Clinic, { ClinicDTO } from '@Portal/shared/models/clinic';

@injectable()
export default class ClinicService {
  static diToken = Symbol('clinic-service');

  private urlPrefix = '/clinics';

  async getClinic() {
    const { data } = await Axios.get<ClinicDTO>(`${this.urlPrefix}/current`);

    return new Clinic(data);
  }
}
