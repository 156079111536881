import Axios from 'axios';
import { injectable } from 'inversify';

import { DentistDTO } from '@Clinic/shared/models/dentist';
import { PatientDTO } from '@Clinic/shared/models/patient';
import { ProfileFamilyMember } from '../models/profile';

@injectable()
export default class PatientsService {
  static diToken = Symbol('patients-service');

  private urlPrefixPatients = '/patients';

  setPatientDefaultDentist = async (
    patientId: PatientDTO['id'],
    params: { dentistId: DentistDTO['id']; familyMemberId?: ProfileFamilyMember['id'] }
  ) => {
    return Axios.patch(`${this.urlPrefixPatients}/${patientId}/dentist`, params);
  };
}
