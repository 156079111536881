import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

import PrivateModule from '@Clinic/modules/Private';
import PublicModule from '@Clinic/modules/Public';
import ErrorHandler from '@shared/components/ErrorHandler';
import Layout from '@Clinic/core/Layout';
import PoweredBy from '@Clinic/shared/components/PoweredBy';
import container from '@Clinic/core/di-container';
import AuthStore from '@Clinic/shared/stores/auth';
import { device } from '@shared/utils/device';

import styles from './Clinic.styles';

export interface ClinicProps extends WithStyles<typeof styles>, RouteComponentProps {}

@observer
class Clinic extends React.Component<ClinicProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  @observable private appCrashed: boolean;

  componentDidCatch() {
    this.appCrashed = true;
  }

  render() {
    const { classes } = this.props;

    if (this.appCrashed) {
      return <ErrorHandler />;
    }

    const { loggedIn } = this.authStore;
    const { phone, tablet } = device;

    return (
      <React.Fragment>
        {phone && !tablet && (
          <DeviceOrientation>
            <Orientation
              className={classes.mobileLandscapeRestriction}
              orientation="landscape"
              alwaysRender={false}
            >
              Please use portrait mode when using mobile.
            </Orientation>
          </DeviceOrientation>
        )}
        <Typography component="div" className={classes.root}>
          <Layout>
            <Route path="/" component={loggedIn ? PrivateModule : PublicModule} />
          </Layout>
          <PoweredBy classes={{ root: classes.poweredBy }} />
        </Typography>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(Clinic));
// deploy;
