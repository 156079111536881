import { DentistDTO } from './dentist';
import { ProfileGender } from '@Clinic/shared/models/profile';

export interface PatientDTO {
  id?: number;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
  dateOfBirth: string;
  gender: ProfileGender;
  password?: string;
  dentistId?: DentistDTO['id'];
  dentist?: {
    id: DentistDTO['id'];
    fullName: string;
  };
}

export default class Patient {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  gender: ProfileGender;
  password: string;
  dentistId: DentistDTO['id'];
  dentist?: {
    id: DentistDTO['id'];
    fullName: string;
  };

  constructor(dto: Partial<PatientDTO>) {
    this.update(dto);
  }

  get asJson(): PatientDTO {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      dateOfBirth: this.dateOfBirth,
      gender: this.gender,
      password: this.password,
      dentistId: this.dentistId,
    };
  }

  update(newData: Partial<PatientDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
