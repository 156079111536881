import { UserRole } from '@Clinic/shared/models/system-user';

export type ModuleAccess = Array<UserRole>;

const ACCESS = {
  enquiries: [UserRole.receptionist, UserRole.dentist],
  profile: [UserRole.patient],
  questionnaire: [UserRole.patient],
  changePassword: [UserRole.patient],
  patients: [UserRole.receptionist],
};

export default ACCESS;
