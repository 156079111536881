import {
  ClinicFont,
  CLINIC_FONT_NAME,
  fontsFromGoogle,
  FALLBACK_CLINIC_FONT_NAMES,
} from '../constants/clinicDesign';

export const getClinicFontName = (font: ClinicFont): string => {
  return `${CLINIC_FONT_NAME[font]}:400,700:latin`; // TODO: remove 500 from styles
};

export const getClinicFontFamily = (font: ClinicFont): string => {
  return `${CLINIC_FONT_NAME[font]}, ${FALLBACK_CLINIC_FONT_NAMES}`;
};

export const isFontFromGoogle = (font: ClinicFont): boolean => {
  return fontsFromGoogle.has(font);
};
