import { createStyles, Theme } from '@material-ui/core/styles';

export default ({ spacing, palette: { primary } }: Theme) =>
  createStyles({
    root: {},
    label: {},
    radio: {
      padding: spacing(0, 2),
    },
    radioChecked: {
      '& + $label': {
        color: primary.main,
      },
    },
  });
