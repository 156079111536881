import { Id } from '@shared/types/common';

export enum EnquiryFeedbackFeeType {
  standardInClinic = 1,
  custom = 2,
  standardRemote = 3,
}

export interface EnquiryFeedbackFee {
  type: EnquiryFeedbackFeeType;
  price: number;
}

export interface EnquiryFeedbackTreatment {
  name: string;
  price: number;
}

export interface EnquiryFeedbackAuthor {
  id: Id;
  fullName: string;
  image?: string;
  specialty?: string;
}

export enum EnquiryFeedbackAppointmentType {
  inClinic = 1,
  remote = 2,
}

export interface EnquiryFeedbackDTO {
  appointmentType: EnquiryFeedbackAppointmentType;
  fee: EnquiryFeedbackFee;
  treatments: Array<EnquiryFeedbackTreatment>;
  timeRequiredMin?: number;
  comment?: string;
  author?: EnquiryFeedbackAuthor;
}

export default class EnquiryFeedback {
  appointmentType: EnquiryFeedbackAppointmentType;
  fee: EnquiryFeedbackFee;
  treatments: Array<EnquiryFeedbackTreatment>;
  timeRequiredMin?: number;
  comment?: string;
  author?: EnquiryFeedbackAuthor;

  constructor(dto: Partial<EnquiryFeedbackDTO>) {
    this.update(dto);
  }

  get asJson(): EnquiryFeedbackDTO {
    return {
      appointmentType: this.appointmentType,
      fee: this.fee,
      timeRequiredMin: this.timeRequiredMin,
      treatments: this.treatments,
      comment: this.comment,
      author: this.author,
    };
  }

  update(newData: Partial<EnquiryFeedbackDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
