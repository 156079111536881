const ROUTES = {
  public: {
    login: '/login',
    forgotPassword: '/forgot-password',
    newPassword: '/new-password',
    account: '/account',
    signUp: '/sign-up',
    acceptableUsePolicy: '/acceptable-use-policy',
    privacyPolicy: '/privacy-policy',
  },
  private: {
    patients: '/patients',
    enquiries: '/enquiries',
    profile: '/profile',
    changePassword: '/change-password',
    questionnaire: '/questionnaire',
    changeDentist: `/change-dentist`,
  },
  initial: '/',
};

export default ROUTES;
