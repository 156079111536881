import * as React from 'react';
import { Field } from 'mobx-react-form';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Radio from '@shared/components/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { observer } from 'mobx-react';

import { Id } from '@shared/types/common';
import Ellipsis from '@shared/components/Ellipsis';

import styles from './RadioGroup.styles';

export enum RadioGroupSerializerType {
  string,
  number,
}

export interface RadioGroupOption {
  id: Id;
  label: React.ReactNode;
}

export type Value = RadioGroupOption['id'];

export interface RadioGroupProps extends WithStyles<typeof styles> {
  options: Array<RadioGroupOption>;
  error?: boolean;
  errorText?: string;
  field?: Field;
  value?: Id;
  label?: React.ReactNode;
  serializerType?: RadioGroupSerializerType;
  onChange?: (value: Id) => any;
}

@observer
class RadioGroup extends React.Component<RadioGroupProps> {
  private handleSerializeValue = (val: Value) => {
    const { serializerType = RadioGroupSerializerType.number } = this.props;

    if (val == undefined) {
      return '';
    }

    const serializers = {
      [RadioGroupSerializerType.string]: String,
      [RadioGroupSerializerType.number]: Number,
    };

    const serialize = serializers[serializerType];

    return serialize(val);
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { field, onChange } = this.props;
    const normalizedValue = this.handleSerializeValue((event.target as HTMLInputElement).value);

    if (field) {
      field.set('value', normalizedValue);
      field.validate();
    }

    if (onChange) {
      onChange(normalizedValue);
    }
  };

  private get name() {
    const { options } = this.props;

    return options.map(({ id }) => id).join();
  }

  render() {
    const { classes, options, field, value, label, error, errorText } = this.props;
    const hasError = Boolean(field?.error) || error;
    const radioGroupErrorText = field ? field.error : errorText;
    const radioGroupValue = field ? field.value : value;

    return (
      <div className={classes.root}>
        {label && (
          <FormLabel className={classes.label} component="legend">
            {label}
          </FormLabel>
        )}
        <MuiRadioGroup
          className={classes.radios}
          name={this.name}
          value={radioGroupValue}
          onChange={this.handleChange}
        >
          {options.map((option) => (
            <Radio
              key={option.id}
              value={option.id}
              label={option.label}
              classes={{ root: classes.formControlLabel }}
            />
          ))}
        </MuiRadioGroup>
        {hasError && <Ellipsis classes={{ root: classes.errorText }} text={radioGroupErrorText} />}
      </div>
    );
  }
}

export default withStyles(styles)(RadioGroup);
