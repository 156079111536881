import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form as BaseForm } from 'mobx-react-form';
import { RouteComponentProps } from 'react-router-dom';

import container from '@Clinic/core/di-container';
import { LayoutView } from '@Clinic/core/Layout/Layout.constants';
import PasswordRequirements from '@Clinic/shared/components/PasswordRequirements';
import AuthStore from '@Clinic/shared/stores/auth';
import LayoutStore from '@Clinic/shared/stores/layout';
import ROUTES from '@Clinic/shared/constants/routes';
import { USER_FIELDS } from '@Clinic/shared/constants/user';
import TextField from '@shared/components/TextField';
import Loading from '@shared/components/Loading';
import Form from '@shared/components/Form';
import AuthHeading, { AuthHeadingPadding } from '@shared/components/AuthHeading';
import ExpiredLink from '@shared/components/ExpiredLink';
import { showNotification } from '@shared/components/Notification';
import { getQueries } from '@shared/utils/common';
import AccountActivateForm from './forms/account-activate';

import styles from './AccountActivate.styles';

export enum AccountActivateType {
  activation = 'activate',
  confirmation = 'confirm',
}

interface AccountActivateParams {
  type: AccountActivateType;
}

export interface AccountActivateProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<AccountActivateParams>,
    WithWidth {}

enum ValidatingStatus {
  pending,
  failed,
  success,
}

@observer
class AccountActivate extends React.Component<AccountActivateProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private form: BaseForm = new AccountActivateForm();
  @observable private validatingStatus = ValidatingStatus.pending;

  constructor(props: AccountActivateProps) {
    super(props);

    this.initiailize();
    this.setPageTitle();
  }

  componentDidUpdate(prevProps: AccountActivateProps) {
    if (prevProps.width !== this.props.width) {
      this.setPageTitle();
    }
  }

  componentWillUnmount() {
    this.layoutStore.setDefaultConfig();
  }

  private setPageTitle = () => {
    this.layoutStore.updateConfig({
      navigationProps: {
        pageDescription: this.layoutStore.view === LayoutView.mobile ? 'Activate account' : null,
      },
    });
  };

  private get queries() {
    return getQueries({ decoder: (str) => str });
  }

  private initiailize = async () => {
    const { userid, token } = this.queries;
    const { type } = this.props.match.params;

    if (userid && token && type) {
      try {
        const init = {
          [AccountActivateType.activation]: this.initializeActivation,
          [AccountActivateType.confirmation]: this.initializeConfirmation,
        };

        await init[type](userid, token);
      } catch {
        this.layoutStore.setDefaultConfig();
        this.validatingStatus = ValidatingStatus.failed;
      }
    } else {
      this.layoutStore.setDefaultConfig();
    }
  };

  private initializeConfirmation = async (userid: string, token: string) => {
    const { history } = this.props;

    try {
      this.validatingStatus = ValidatingStatus.pending;

      await this.authStore.confirmAccount(userid, encodeURIComponent(token));

      showNotification('Account has been successfully activated.');
      history.push(ROUTES.public.login);
    } catch (err) {
      this.layoutStore.setDefaultConfig();
      throw err;
    }
  };

  private initializeActivation = async (userid: string, token: string) => {
    try {
      this.validatingStatus = ValidatingStatus.pending;

      await this.authStore.validateAccountActivationLink(userid, encodeURIComponent(token));

      this.validatingStatus = ValidatingStatus.success;
    } catch (err) {
      throw err;
    }
  };

  private handleSubmit = async (data: { password: string; newPassword: string }) => {
    const { history } = this.props;
    const { userid, token } = this.queries;

    try {
      await this.authStore.activateAccount(userid, {
        token,
        newPassword: data.newPassword,
      });

      showNotification('New password has been successfully set.');
      history.push(ROUTES.public.login);
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { userid, token } = this.queries;
    const { classes } = this.props;

    if (!userid || !token || this.validatingStatus === ValidatingStatus.failed) {
      return <ExpiredLink link={ROUTES.public.login} />;
    }

    if (this.validatingStatus === ValidatingStatus.pending) {
      return <Loading absolute />;
    }

    const placeholder = '123ABCabc!@#';

    return (
      <div className={classes.root}>
        <AuthHeading
          heading="Enter password"
          subheading="Please enter a password to create your profile and log in to the system."
          padding={AuthHeadingPadding.none}
        />
        <Form
          footerFullWidth
          withControls
          withCancelButton={false}
          formInstance={this.form}
          buttonProps={{
            submit: {
              text: 'Save & continue',
              fullWidth: this.layoutStore.view === LayoutView.mobile,
            },
          }}
          onSubmit={this.handleSubmit}
        >
          <TextField
            label="New password"
            placeholder={placeholder}
            field={this.form.$(USER_FIELDS.password)}
          />
          <PasswordRequirements />
          <TextField
            label="Confirm new password"
            placeholder={placeholder}
            field={this.form.$(USER_FIELDS.newPassword)}
          />
        </Form>
      </div>
    );
  }
}

export default withStyles(styles)(withWidth()(AccountActivate));
