import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ palette: { primary } }: Theme) {
  return createStyles({
    root: {
      fill: 'none',
      color: primary.main,
    },
  });
}
