import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default function styles({ spacing, palette: { colors }, breakpoints }: Theme) {
  return createStyles({
    root: {},
    pageDescription: {
      fontSize: 20,
      fontWeight: 700,
      color: colors.black,
    },
    backBtn: {},
    cancelBtn: {},
    cancelButtonLabel: {
      fontSize: 16,
    },
    cancelButtonLabelDesktop: {
      color: colors.red,
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      pageDescription: {
        fontSize: 16,
      },
    },
  });
}
