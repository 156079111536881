import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import MuiRadio, { RadioProps as BaseRadioProps } from '@material-ui/core/Radio';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import { Field } from 'mobx-react-form';
import { observer } from 'mobx-react';

import { getScrollAttr } from '@shared/utils/form';
import RadioIcon, { RadioIconType } from '@shared/icons/Radio';

import styles from './Radio.styles';

export interface RadioProps
  extends WithStyles<typeof styles>,
    Omit<FormControlLabelProps, 'classes' | 'control' | 'onChange'> {
  inputProps?: BaseRadioProps['inputProps'] & { [k: string]: string | number };
  field?: Field;
  onChange?: (checked: boolean, e?) => any;
}

@observer
class Radio extends React.Component<RadioProps> {
  private handleChange = (e, checked: boolean) => {
    const { field, onChange } = this.props;

    if (field) {
      field.set('value', checked);
    }

    if (onChange) {
      onChange(checked, e);
    }
  };

  render() {
    const { field, checked, classes, onChange, inputProps, ...otherProps } = this.props;

    return (
      <FormControlLabel
        {...otherProps}
        classes={{
          root: classes.root,
          label: classes.label,
        }}
        control={
          <MuiRadio
            inputProps={inputProps}
            classes={{
              root: classes.radio,
              checked: classes.radioChecked,
            }}
            {...getScrollAttr(field?.name || otherProps.name)}
            icon={<RadioIcon iconType={RadioIconType.unchecked} />}
            checkedIcon={<RadioIcon iconType={RadioIconType.checked} />}
            checked={field ? field.value : checked}
            onChange={this.handleChange}
          />
        }
      />
    );
  }
}

export default withStyles(styles)(Radio);
