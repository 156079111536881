import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form as BaseForm } from 'mobx-react-form';
import { RouteComponentProps } from 'react-router-dom';

import PasswordRequirements from '@Clinic/shared/components/PasswordRequirements';
import container from '@Clinic/core/di-container';
import { LayoutView } from '@Clinic/core/Layout/Layout.constants';
import AuthStore from '@Clinic/shared/stores/auth';
import LayoutStore from '@Clinic/shared/stores/layout';
import { USER_FIELDS } from '@Clinic/shared/constants/user';
import ROUTES from '@Clinic/shared/constants/routes';
import Loading from '@shared/components/Loading';
import TextField from '@shared/components/TextField';
import Form from '@shared/components/Form';
import AuthHeading, { AuthHeadingPadding } from '@shared/components/AuthHeading';
import ExpiredLink from '@shared/components/ExpiredLink';
import { showNotification } from '@shared/components/Notification';
import { getQueries } from '@shared/utils/common';
import NewPasswordForm from './forms/new-password';

import styles from './NewPassword.styles';

export interface NewPasswordProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithWidth {}

enum ValidatingStatus {
  pending,
  failed,
  success,
}

@observer
class NewPassword extends React.Component<NewPasswordProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private form: BaseForm = new NewPasswordForm();
  @observable private validatingStatus = ValidatingStatus.pending;

  constructor(props: NewPasswordProps) {
    super(props);

    this.setPageTitle();
    this.initiailize();
  }

  componentDidUpdate(prevProps: NewPasswordProps) {
    if (prevProps.width !== this.props.width) {
      this.setPageTitle();
    }
  }

  componentWillUnmount() {
    this.layoutStore.setDefaultConfig();
  }

  private setPageTitle = () => {
    this.layoutStore.updateConfig({
      navigationProps: {
        pageDescription: this.layoutStore.view === LayoutView.mobile ? 'Change password' : null,
      },
    });
  };

  private get queries() {
    return getQueries({ decoder: (str) => str });
  }

  private initiailize = async () => {
    const { userid, token } = this.queries;

    if (userid && token) {
      try {
        this.validatingStatus = ValidatingStatus.pending;

        await this.authStore.validatePasswordRecoveryLink(userid, encodeURIComponent(token));

        this.validatingStatus = ValidatingStatus.success;
      } catch {
        this.layoutStore.setDefaultConfig();
        this.validatingStatus = ValidatingStatus.failed;
      }
    } else {
      this.layoutStore.setDefaultConfig();
    }
  };

  private handleSubmit = async (data: { password: string; newPassword: string }) => {
    const { history } = this.props;
    const { userid, token } = this.queries;

    try {
      await this.authStore.setNewPassword(userid, {
        token,
        newPassword: data.newPassword,
      });

      showNotification('Your password has been successfully reset.');
      history.push(ROUTES.public.login);
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { userid, token } = this.queries;
    const { classes } = this.props;
    const placeholder = '123ABCabc!@#';

    if (!userid || !token || this.validatingStatus === ValidatingStatus.failed) {
      return <ExpiredLink link={ROUTES.public.login} />;
    }

    if (this.validatingStatus === ValidatingStatus.pending) {
      return <Loading absolute />;
    }

    return (
      <div className={classes.root}>
        <AuthHeading subheading="Please create a new password" padding={AuthHeadingPadding.none} />
        <Form
          footerFullWidth
          withControls
          withCancelButton={false}
          formInstance={this.form}
          buttonProps={{
            submit: {
              text: 'Save new password',
              fullWidth: this.layoutStore.view === LayoutView.mobile,
            },
          }}
          onSubmit={this.handleSubmit}
        >
          <TextField
            withPasswordToggle
            label="Password"
            placeholder={placeholder}
            field={this.form.$(USER_FIELDS.newPassword)}
            classes={{ root: classes.passwordField }}
          />
          <PasswordRequirements />
          <TextField
            withPasswordToggle
            label="Confirm new password"
            placeholder={placeholder}
            field={this.form.$(USER_FIELDS.newPasswordConfirm)}
            classes={{ root: classes.passwordField }}
          />
        </Form>
      </div>
    );
  }
}

export default withStyles(styles)(withWidth()(NewPassword));
