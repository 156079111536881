import * as React from 'react';
import { RouteComponentProps, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import NoMatch from '@shared/components/NoMatch';
import Lazyload from '@shared/components/LazyLoad';
import ROUTES from '@Clinic/shared/constants/routes';
import ACCESS, { ModuleAccess } from '@Clinic/shared/constants/access';
import container from '@Clinic/core/di-container';
import AuthStore from '@Clinic/shared/stores/auth';
import { MergeTypes } from '@shared/types/common';
import ErrorHandler from '@shared/components/ErrorHandler';
import { getDefaultRoute } from '@Clinic/shared/utils/common';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import styles from './Private.styles';

const Enquiries = React.lazy(() => import('./submodules/Enquiries'));
const Profile = React.lazy(() => import('./submodules/Profile'));
const Questionnaire = React.lazy(() => import('./submodules/Questionnaire'));
const ChangePassword = React.lazy(() => import('./submodules/ChangePassword'));
const Patients = React.lazy(() => import('./submodules/Patients'));
const DentistChange = React.lazy(() => import('./submodules/DentistChange'));

export interface PrivateModuleProps extends RouteComponentProps, WithStyles<typeof styles> {}

const PUBLIC_ROUTES = Object.values(ROUTES.public);

@observer
class Private extends React.Component<PrivateModuleProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  @observable private appCrashed: boolean;

  componentDidCatch() {
    this.appCrashed = true;
  }

  private get routes(): Array<{
    access: ModuleAccess;
    routeProps: MergeTypes<{ component: React.ComponentType<any> }, Omit<RouteProps, 'component'>>;
  }> {
    const submodules = [
      {
        routeProps: { path: `${ROUTES.private.enquiries}/:requestType`, component: Enquiries }, // TODO list all enquiry types
        access: ACCESS.enquiries,
      },
      {
        routeProps: { path: ROUTES.private.profile, component: Profile },
        access: ACCESS.profile,
      },
      {
        routeProps: { path: ROUTES.private.questionnaire, component: Questionnaire },
        access: ACCESS.questionnaire,
      },
      {
        routeProps: { path: ROUTES.private.changePassword, component: ChangePassword },
        access: ACCESS.changePassword,
      },
      {
        routeProps: { path: ROUTES.private.patients, component: Patients },
        access: ACCESS.patients,
      },
      {
        routeProps: { path: `${ROUTES.private.changeDentist}/:id`, component: DentistChange },
        access: ACCESS.profile,
      },
    ];

    return submodules.filter((module) => {
      return module.access.includes(this.authStore.user.role);
    });
  }

  componentDidMount() {
    this.authStore.resetLoginRedirectURL();
  }

  private get modules() {
    const { classes } = this.props;
    const redirectPathname = this.authStore.loginRedirectURL || getDefaultRoute();

    return (
      <Lazyload classes={{ root: classes.moduleLoading }}>
        <Switch>
          {this.routes.map(({ routeProps: { component: $component, ...otherRouteProps } }) => (
            <Route key={String(otherRouteProps.path)} component={$component} {...otherRouteProps} />
          ))}
          <Redirect exact from={ROUTES.initial} to={redirectPathname} />
          {PUBLIC_ROUTES.map((route) => (
            <Redirect exact key={route} from={route} to={redirectPathname} />
          ))}
          <Route
            render={() => {
              return <NoMatch loggedIn />;
            }}
          />
        </Switch>
      </Lazyload>
    );
  }

  render() {
    return this.appCrashed ? <ErrorHandler /> : this.modules;
  }
}

export default withStyles(styles)(Private);
