import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';

import BackButton, { BackButtonProps } from '@shared/components/BackButton';
import Button, { ButtonProps } from '@shared/components/Button';
import { LayoutView } from '@Clinic/core/Layout/Layout.constants';

import styles from './Navigation.styles';

export interface NavigationProps extends WithStyles<typeof styles> {
  layoutView: LayoutView;
  backButtonProps?: Partial<BackButtonProps>;
  cancelButtonProps?: Partial<ButtonProps> | null;
  pageDescription?: React.ReactNode;
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const { classes, backButtonProps, cancelButtonProps, layoutView, pageDescription } = props;
  const variant: ButtonProps['variant'] = layoutView === LayoutView.mobile ? 'text' : 'contained';
  const color: ButtonProps['color'] = layoutView === LayoutView.mobile ? 'primary' : 'secondary';
  const cancelButtonWidth = layoutView === LayoutView.mobile ? 52 : 78;
  const backButtonWidth = layoutView === LayoutView.mobile ? 48 : 84;

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classes.root}
    >
      {backButtonProps ? (
        <BackButton
          {...backButtonProps}
          color={color}
          variant={variant}
          classes={{
            root: classes.backBtn,
          }}
        />
      ) : (
        <span style={{ width: backButtonWidth }} />
      )}
      {pageDescription && <div className={classes.pageDescription}>{pageDescription}</div>}
      {cancelButtonProps ? (
        <Button
          size="small"
          {...cancelButtonProps}
          style={{
            width: cancelButtonWidth,
          }}
          color={color}
          text="Cancel"
          variant={variant}
          classes={{
            root: classes.cancelBtn,
            label: cx(classes.cancelButtonLabel, {
              [classes.cancelButtonLabelDesktop]: layoutView === LayoutView.desktop,
            }),
          }}
        />
      ) : (
        <span style={{ width: cancelButtonWidth }} />
      )}
    </Grid>
  );
};

export default withStyles(styles)(Navigation);
