import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing }: Theme) {
  return createStyles({
    root: {
      fontSize: 12,
      marginTop: spacing(2),
    },
  });
}
