import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import Dentist from './components/Dentist';
import DentistModel from '@Clinic/shared/models/dentist';
import { DentistDTO } from '@Clinic/shared/models/dentist';

import styles from './DentistSelect.styles';

export interface DentistSelectProps extends WithStyles<typeof styles> {
  dentists: Array<DentistModel>;
  selectedDentistId: DentistDTO['id'] | undefined;
  onChange: (dentistId?: DentistDTO['id']) => any;
}

const DentistSelect: React.FC<DentistSelectProps> = (props) => {
  const { classes, dentists, selectedDentistId, onChange } = props;

  return (
    <div className={classes.root}>
      <h6 className={classes.questionHeading}>My preferred dentist is...</h6>
      <Dentist
        classes={{
          root: classes.dentist,
        }}
        selected={selectedDentistId === undefined}
        fullName="Recommend me a suitable dentist"
        onSelect={onChange}
      />
      {dentists.map((dentist) => (
        <Dentist
          classes={{
            root: classes.dentist,
          }}
          selected={dentist.id == selectedDentistId}
          key={dentist.id}
          id={dentist.id}
          fullName={dentist.fullName}
          specialty={dentist.specialty}
          imgSrc={dentist.image}
          onSelect={onChange}
        />
      ))}
    </div>
  );
};

export default withStyles(styles)(DentistSelect);
