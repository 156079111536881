import { injectable } from 'inversify';
import { observable, computed, action } from 'mobx';
import Cookie from 'js-cookie';
import decodeJWT from 'jwt-decode';

import container from '@Clinic/core/di-container';
import AuthService from '@Clinic/shared/services/auth';
import SystemUser, { SystemUserDTO } from '@Clinic/shared/models/system-user';
import { PatientDTO } from '@Clinic/shared/models/patient';
import ROUTES from '@Clinic/shared/constants/routes';
import { Id } from '@shared/types/common';
import history from '@shared/utils/history';

const COOKIES_KEYS = {
  tokens: {
    access: 'pd_clinic_access_token',
    refresh: 'pd_clinic_refresh_token',
  },
};

export enum TokenRefreshStatus {
  refreshing,
  refreshed,
  initial,
}

const LOGIN_REDIRECT_URL_KEY = 'pocket-dentist_loginRedirectURL';
const PREVIOUS_USER_ROLE_KEY = 'pocket-dentist_previousUserRole';

@injectable()
export default class AuthStore {
  static diToken = Symbol('auth-store');
  private service = container.get<AuthService>(AuthService.diToken);
  @observable tokenRefreshStatus = TokenRefreshStatus.initial;
  @observable private _user;

  @computed get user(): SystemUser {
    return this._user;
  }

  @computed get loggedIn(): boolean {
    return Boolean(this.user);
  }

  get tokens() {
    return {
      refresh: Cookie.get(COOKIES_KEYS.tokens.refresh),
      access: Cookie.get(COOKIES_KEYS.tokens.access),
    };
  }

  get loginRedirectURL() {
    const roleCondition = this.previousUserRole ? this.user?.role === this.previousUserRole : true;

    if (roleCondition) {
      return localStorage.getItem(LOGIN_REDIRECT_URL_KEY);
    }

    return '';
  }

  initialize() {
    this.setUser();

    if (!this.loggedIn) {
      const isPrivatePathname = Object.values(ROUTES.private).some((privatePath) => {
        return history.location.pathname.includes(privatePath);
      });

      if (isPrivatePathname) {
        this.setLoginRedirectURL();
      }
    }
  }

  private setLoginRedirectURL = () => {
    localStorage.setItem(LOGIN_REDIRECT_URL_KEY, history.location.pathname);
  };

  @action private async setUser() {
    const token = Cookie.get(COOKIES_KEYS.tokens.access);

    if (!token) {
      return;
    }

    try {
      const data = decodeJWT<SystemUserDTO>(token);

      this._user = new SystemUser(data);
    } catch {
      this.logout();
    }
  }

  private setTokens(tokens: { accessToken: string; refreshToken: string }) {
    Cookie.set(COOKIES_KEYS.tokens.access, tokens.accessToken);
    Cookie.set(COOKIES_KEYS.tokens.refresh, tokens.refreshToken);
  }

  private setAuthData(tokens: { accessToken: string; refreshToken: string }) {
    this.setTokens(tokens);
    this.setUser();
  }

  async login(data: { username: string; password: string }) {
    const authData = await this.service.login(data);

    this.setAuthData(authData);
  }

  signUp(data: Partial<PatientDTO>) {
    return this.service.signUp(data);
  }

  @action refreshToken = async () => {
    if (this.tokenRefreshStatus === TokenRefreshStatus.refreshing) {
      return;
    }

    if (!this.tokens.refresh) {
      this.logout();

      throw new Error('User is unauthorized');
    }

    this.tokenRefreshStatus = TokenRefreshStatus.refreshing;

    try {
      const authData = await this.service.refreshToken(this.tokens.refresh);

      this.setAuthData(authData);

      this.tokenRefreshStatus = TokenRefreshStatus.refreshed;
    } catch (err) {
      this.logout();
      throw err;
    }
  };

  logout = (clearLoginRedirectURL?: boolean) => {
    if (!clearLoginRedirectURL) {
      this.setLoginRedirectURL();
    }

    this.setPreviousUserRole();
    this.reset({ loginRedirectURL: clearLoginRedirectURL });
  };

  activateAccount(userId: Id, data: { newPassword: string; token: string }) {
    return this.service.activateAccount(userId, data);
  }

  confirmAccount(userId: Id, token: string) {
    return this.service.confirmAccount(userId, token);
  }

  validateAccountActivationLink(userId: Id, token: string) {
    return this.service.validateAccountActivationLink(userId, token);
  }

  validatePasswordRecoveryLink(userId: Id, token: string) {
    return this.service.validatePasswordRecoveryLink(userId, token);
  }

  resetPassword(email: string) {
    return this.service.resetPassword(email);
  }

  setNewPassword(userId: Id, data: { newPassword: string; token: string }) {
    return this.service.setNewPassword(userId, data);
  }

  resetLoginRedirectURL = () => {
    localStorage.setItem(LOGIN_REDIRECT_URL_KEY, '');
  };

  private get previousUserRole() {
    return localStorage.getItem(PREVIOUS_USER_ROLE_KEY);
  }

  private setPreviousUserRole = () => {
    localStorage.setItem(PREVIOUS_USER_ROLE_KEY, this.user.role);
  };

  private resetTokens() {
    Cookie.remove(COOKIES_KEYS.tokens.access);
    Cookie.remove(COOKIES_KEYS.tokens.refresh);
  }

  @action private reset(config?: { loginRedirectURL?: boolean }) {
    this.resetTokens();

    this.tokenRefreshStatus = TokenRefreshStatus.initial;
    this._user = undefined;

    if (config?.loginRedirectURL) {
      this.resetLoginRedirectURL();
    }
  }
}
