import { EnquiryPositiveNegativeCategory } from '@Clinic/shared/models/enquiry/enquiry';

export enum QuestionType {
  text = 1,
  singleChoice = 2,
  multiChoice = 3,
  image = 4,
}

export enum QuestionCategory {
  general = 1,
  virtualConsultation = 2,
  smileImprovements = 3,
  dentalProblem = 4,
  dentalProblemGeneral = 8,
  dentalProblemUpperTeeth = 5,
  dentalProblemLowerTeeth = 9,
  dentalProblemJaw = 6,
  covid = 7,
}

export interface Answer {
  id: number;
  text: string;
  nextQuestionId?: QuestionDTO['id'];
  positiveNegativeCategory?: EnquiryPositiveNegativeCategory;
}

export interface QuestionGuideScreenImagePlaceHolder {
  titleImage: string;
  title: string;
}

export interface QuestionGuideScreen {
  imagePlaceholders: Array<QuestionGuideScreenImagePlaceHolder>;
}

export interface QuestionGuide {
  allMandatory: boolean;
  mandatoryImagesCount: number;
  screens: Array<QuestionGuideScreen>;
  videoLink?: string;
}

export interface QuestionDTO {
  answers: Array<Answer>;
  category: QuestionCategory;
  id: number;
  isOptional: boolean;
  text: string;
  type: QuestionType;
  guide?: QuestionGuide;
  nextQuestionId?: QuestionDTO['id'];
}

export default class Question {
  answers: Array<Answer>;
  category: QuestionCategory;
  guide: QuestionGuide | undefined;
  id: number;
  isOptional: boolean;
  text: string;
  type: QuestionType;
  nextQuestionId?: QuestionDTO['id'];

  constructor(dto: Partial<QuestionDTO>) {
    this.update(dto);
  }

  get asJson(): QuestionDTO {
    return {
      answers: this.answers,
      category: this.category,
      guide: this.guide,
      id: this.id,
      isOptional: this.isOptional,
      text: this.text,
      type: this.type,
      nextQuestionId: this.nextQuestionId,
    };
  }

  update(newData: Partial<QuestionDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
