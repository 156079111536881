import { createStyles } from '@material-ui/core/styles';

export default function styles() {
  return createStyles({
    moduleLoading: {
      paddingTop: 100,
      paddingBottom: 100,
      minHeight: 300,
    },
  });
}
