import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './Radio.styles';

export enum RadioIconType {
  checked,
  unchecked,
}

interface RadioIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {
  iconType: RadioIconType;
}

const icon = {
  [RadioIconType.unchecked]: (
    <>
      <g filter="url(#filter0_i)">
        <circle cx="8" cy="8" r="8" fill="#F6F6F6" />
      </g>
      <circle cx="8" cy="8" r="7.5" stroke="#E0E0E0" />
      <defs>
        <filter
          id="filter0_i"
          x="0"
          y="0"
          width="16"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </>
  ),
  [RadioIconType.checked]: (
    <>
      <circle cx="8" cy="8" r="8" fill="currentColor" />
      <circle cx="8" cy="8" r="3" fill="white" />
    </>
  ),
};

const RadioIcon: React.FC<RadioIconProps> = ({ iconType, ...otherProps }) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" color="primary" {...otherProps}>
      {icon[iconType]}
    </SvgIcon>
  );
};

export default withStyles(styles)(RadioIcon);
