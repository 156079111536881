import { injectable } from 'inversify';
import merge from 'lodash/merge';
import { observable, computed, action, toJS } from 'mobx';
import { createRef } from 'react';

import { NavigationProps } from '@Clinic/core/Layout/components/Navigation';
import { LayoutView } from '@Clinic/core/Layout/Layout.constants';
import defaultLogoImage from '@public/assets/images/default-clinic-logo.svg';
import { HeaderProps } from '@Clinic/core/Layout/components/Header';

export interface LayoutConfig {
  moduleFixedHeight?: boolean;
  navigationProps?: Pick<
    NavigationProps,
    'backButtonProps' | 'cancelButtonProps' | 'pageDescription'
  >;
  showHeader?: boolean;
  headerProps?: Pick<HeaderProps, 'centeredLogo' | 'withLogo'>;
  content?: {
    width?: number | string;
  };
  classes?: {
    module?: string;
  };
}

const DEFAULT_CONFIG = undefined;

@injectable()
export default class LayoutStore {
  static diToken = Symbol('layout-store');

  private _moduleRef = createRef<HTMLDivElement>();
  @observable private _view: LayoutView;
  @observable private _config: LayoutConfig | undefined = DEFAULT_CONFIG;
  @observable private _logo: string = defaultLogoImage;

  get moduleRef() {
    return this._moduleRef;
  }

  @computed get view() {
    return this._view;
  }

  @computed get config() {
    return toJS(this._config);
  }

  @computed get logo() {
    return this._logo;
  }

  scrollToTop = () => {
    const ref = this._moduleRef.current;

    if (ref) {
      ref.scrollTop = 0;
    }
  };

  @action setLogo = (logoUrl: string) => {
    this._logo = logoUrl;
  };

  @action setView = (view: LayoutView) => {
    this._view = view;
  };

  @action updateConfig = (config: Partial<LayoutConfig>) => {
    this._config = merge(this.config, config);
  };

  @action setDefaultConfig = () => {
    this._config = DEFAULT_CONFIG;
  };
}
