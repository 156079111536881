import { createStyles, Theme, fade } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default function styles({
  spacing,
  palette: { colors, primary },
  breakpoints,
  radius,
}: Theme) {
  const activeLinkStyles: CSSProperties = {
    color: colors.black,
    background: fade(primary.main, 0.08),
    borderRadius: radius.containers.primary,
  };

  return createStyles({
    root: {},
    logo: {
      objectFit: 'contain',
      height: 48,
      maxWidth: 200,
    },
    links: {
      overflowX: 'auto',
    },
    linksTitle: {
      color: colors.grey,
      marginRight: spacing(5),
      fontSize: 14,
    },
    link: {
      padding: spacing(2, 4),
      margin: spacing(0, 1),
      color: primary.main,
      fontWeight: 700,
      fontSize: 14,

      '&:hover': {
        ...activeLinkStyles,
      },
    },
    activeLink: {
      ...activeLinkStyles,
    },
    logoutBtn: {
      marginLeft: spacing(3),
    },
    icon: {
      fontSize: 22,
      marginRight: spacing(2),
    },
    logoutIcon: {},

    [breakpoints.between(BREAKPOINT.mobile, BREAKPOINT.tablet)]: {
      logo: {
        maxWidth: 150,
      },
      link: {
        fontSize: 12,
        padding: spacing(2),
      },
      logoutBtn: {
        fontSize: 12,
      },
      logoutIcon: {
        fontSize: 16,
      },
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      logo: {
        height: 42,
        maxWidth: 128,
      },
    },
  });
}
