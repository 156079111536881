import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { CSS_VARIABLES, LayoutView, BREAKPOINT } from './Layout.constants';
import { getScreenHeight } from '@shared/utils/common';
import { browser } from '@shared/utils/browser';

export const setCSSScreenHeightVariable = () => {
  document.documentElement.style.setProperty(CSS_VARIABLES.screenHeight, `${getScreenHeight()}px`);
};

export const getScreenHeightSource = () => {
  if (browser?.name === 'ie') {
    return '100vh';
  }

  return `var(${CSS_VARIABLES.screenHeight})`;
};

export const getLayoutView = (currentWidth: Breakpoint) => {
  const views = [
    {
      condition: isWidthDown(BREAKPOINT.mobile, currentWidth),
      view: LayoutView.mobile,
    },
    {
      condition: isWidthUp(BREAKPOINT.mobile, currentWidth),
      view: LayoutView.desktop,
    },
  ];

  try {
    return views.find(({ condition }) => condition)?.view as LayoutView;
  } catch {
    return LayoutView.mobile;
  }
};
