import { EnquirySlotsDTO, EnquiryStatus } from '@Clinic/shared/models/enquiry';
import { WorkingTime } from '@Portal/shared/models/clinic';

export interface ReceptionistAppointment {
  enquiryId: EnquirySlotsDTO['enquiryId'];
  time: WorkingTime;
}
export interface AppointmentDTO {
  enquiryStatus: EnquiryStatus;
  patientName: string;
  requestDate: string;
}

export default class Appointment {
  enquiryStatus: EnquiryStatus;
  patientName: string;
  requestDate: string;

  constructor(dto: Partial<AppointmentDTO>) {
    this.update(dto);
  }

  get asJson(): AppointmentDTO {
    return {
      enquiryStatus: this.enquiryStatus,
      patientName: this.patientName,
      requestDate: this.requestDate,
    };
  }

  update(newData: Partial<AppointmentDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
