import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, shortcuts }: Theme) {
  return createStyles({
    input: {
      width: '100% !important',
      height: '3rem !important',
      fontSize: 16,
      boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.16) !important',
    },
    dropdown: {
      boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.16) !important',
    },
    errorText: {
      ...shortcuts.errorText,
    },
    legend: {
      margin: spacing(4, 0, 1),
    },
  });
}
