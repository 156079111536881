import { injectable } from 'inversify';
import Axios from 'axios';

import Profile, { ProfileDTO } from '@Clinic/shared/models/profile';
import Patient, { PatientDTO } from '@Clinic/shared/models/patient';
import Appointment, { AppointmentDTO } from '@Clinic/shared/models/appointment';
import { ProfileFamilyMember, PrimaryProfileFamilyMember } from '@Clinic/shared/models/profile';
import { Id } from '@shared/types/common';
import { ListRequestParams, ListResponse } from '@shared/types/services';
import { REQUEST_PARAMS } from '@shared/constants/services';
import { USER_FIELDS } from '@Clinic/shared/constants/user';

@injectable()
export default class UsersService {
  static diToken = Symbol('users-service');

  private patientsURLPrefix = '/patients';

  getPatientsListQuery({ pagination, sorting, searchKeyword }: ListRequestParams) {
    return `
      {
        patients(
          ${REQUEST_PARAMS.pagination.fields.page}: ${pagination.page}, 
          ${REQUEST_PARAMS.pagination.fields.pageSize}: ${pagination.pageSize},
          ${REQUEST_PARAMS.searchKeyword}:  "${searchKeyword}",
          ${REQUEST_PARAMS.sorting.name}: { ${REQUEST_PARAMS.sorting.fields.type}: ${sorting?.type}, ${REQUEST_PARAMS.sorting.fields.field}: ${sorting?.field} }, 
        ) {
          ${REQUEST_PARAMS.items} {
            ${USER_FIELDS.id}, 
            ${USER_FIELDS.fullName}, 
            ${USER_FIELDS.email},
            ${USER_FIELDS.phoneNumber},
            ${USER_FIELDS.familyMembersCount},
            ${USER_FIELDS.dentist.name} {
              ${USER_FIELDS.dentist.fields.id},
              ${USER_FIELDS.dentist.fields.fullName}
            }
          }
          ${REQUEST_PARAMS.pageInfo.name} {
            ${REQUEST_PARAMS.pageInfo.fields.totalCount},
            ${REQUEST_PARAMS.pageInfo.fields.hasPreviousPage},
            ${REQUEST_PARAMS.pageInfo.fields.hasNextPage}
          }
        }
      }
    `;
  }

  async getPatients(params: ListRequestParams) {
    const { data } = await Axios.get<{ patients: ListResponse<PatientDTO> }>(
      this.patientsURLPrefix,
      { params: { query: this.getPatientsListQuery(params) } }
    );

    const {
      patients: { items, pageInfo },
    } = data;

    return {
      pageInfo,
      items: items.map((dto) => new Patient(dto)),
    };
  }

  async getPatientProfile() {
    const { data } = await Axios.get<ProfileDTO>(`${this.patientsURLPrefix}/current`);

    return new Profile(data);
  }

  createPatientProfile(data: ProfileFamilyMember) {
    return Axios.post(`${this.patientsURLPrefix}/family-member`, data);
  }

  editPatientProfile(data: PrimaryProfileFamilyMember) {
    return Axios.put(this.patientsURLPrefix, data);
  }

  deletePatientProfile() {
    return Axios.delete(`${this.patientsURLPrefix}/current`);
  }

  editPatientProfileFamilyMember(id: Id, data: ProfileFamilyMember) {
    return Axios.put(`${this.patientsURLPrefix}/family-member/${id}`, data);
  }

  deletePatientProfileFamilyMember(id: Id) {
    return Axios.delete(`${this.patientsURLPrefix}/family-member/${id}`);
  }

  changePassword(data: { oldPassword: string; newPassword: string }) {
    const url = `${this.patientsURLPrefix}/current/password`;

    return Axios.put(url, data);
  }

  async getAppointments() {
    const { data } = await Axios.get<Array<AppointmentDTO>>(
      `${this.patientsURLPrefix}/current/enquiries`
    );

    return data.map((dto) => new Appointment(dto));
  }
}
