import Axios from 'axios';
import { injectable } from 'inversify';
import qs from 'qs';

import { Id } from '@shared/types/common';
import { PatientDTO } from '@Clinic/shared/models/patient';

@injectable()
export default class AuthService {
  static diToken = Symbol('auth-service');

  private authURLPrefix = '/auth';
  private usersURLPrefix = '/users';
  private patientsURLPrefix = '/patients';

  async login(credentials: { username: string; password: string }) {
    try {
      const {
        data: { access_token, refresh_token },
      } = await Axios.post(
        this.authURLPrefix,
        qs.stringify({ grant_type: 'password', ...credentials }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      return { accessToken: access_token, refreshToken: refresh_token };
    } catch (err) {
      throw err;
    }
  }

  signUp(data: Partial<PatientDTO>) {
    return Axios.post(this.patientsURLPrefix, data);
  }

  async refreshToken(token: string) {
    try {
      const {
        data: { access_token, refresh_token },
      } = await Axios.post(
        this.authURLPrefix,
        qs.stringify({ grant_type: 'refresh_token', refresh_token: token }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      return { accessToken: access_token, refreshToken: refresh_token };
    } catch (err) {
      throw err;
    }
  }

  activateAccount(userId: Id, data: { newPassword: string; token: string }) {
    const url = `${this.usersURLPrefix}/${userId}/password`;

    return Axios.put(url, data);
  }

  validatePasswordRecoveryLink(userId: Id, token: string) {
    const url = `${this.usersURLPrefix}/${userId}/password/reset/token/validate?passwordRecoveryToken=${token}`;

    return Axios.put(url);
  }

  validateAccountActivationLink(userId: Id, token: string) {
    const url = `${this.usersURLPrefix}/${userId}/activation-token/validate?activationToken=${token}`;

    return Axios.post(url);
  }

  confirmAccount(userId: Id, token: string) {
    const url = `${this.patientsURLPrefix}/${userId}/activate?activationToken=${token}`;

    return Axios.post(url);
  }

  resetPassword(email: string) {
    const url = `${this.usersURLPrefix}/password/reset/request`;

    return Axios.post(url, email);
  }

  setNewPassword(userId: Id, data: { newPassword: string; token: string }) {
    const url = `${this.usersURLPrefix}/${userId}/password/reset`;

    return Axios.put(url, data);
  }
}
