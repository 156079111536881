import { injectable } from 'inversify';
import { observable, action, computed, toJS } from 'mobx';

import DentistService from '@Clinic/shared/services/dentists';
import container from '@Clinic/core/di-container';
import Dentist from '@Clinic/shared/models/dentist';

@injectable()
export default class DentistsStore {
  static diToken = Symbol('dentists-store');
  private service = container.get<DentistService>(DentistService.diToken);

  @observable private _list: Array<Dentist> = [];

  @observable loading = {
    list: false,
  };
  @observable error = {
    listGET: false,
  };

  @computed get list() {
    return toJS(this._list);
  }

  @action getList = async () => {
    this.loading.list = true;
    this.error.listGET = false;

    try {
      this._list = await this.service.getList();

      return this._list;
    } catch {
      this.error.listGET = true;
    } finally {
      this.loading.list = false;
    }
  };
}
