import { createStyles, Theme } from '@material-ui/core/styles';
import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default function styles({ shortcuts, spacing, palette: { colors }, breakpoints }: Theme) {
  return createStyles({
    root: {},
    logoWrapper: {
      width: '100%',
      marginBottom: spacing(9),
      textAlign: 'center',
    },
    logo: {
      maxHeight: 136,
      maxWidth: '100%',
    },
    forgotPasswordLinkWrapper: {
      marginBottom: spacing(6),
    },
    forgotPasswordLink: {
      ...shortcuts.link.primary,
      marginBottom: -spacing(),
    },
    signUpLink: {
      width: '100%',
    },
    divider: {
      textAlign: 'center',
      position: 'relative',
      textTransform: 'uppercase',
      width: '100%',
      margin: spacing(4, 'auto'),
      color: colors.grey2,

      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        borderTop: `1px solid ${colors.greyLight5}`,
        width: 'calc(50% - 16px)',
      },

      '&::after': {
        left: 'auto',
        right: 0,
      },
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      logoWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: spacing(12, 0),
      },
      logo: {},
    },
  });
}
