import Axios from 'axios';
import { injectable } from 'inversify';

import Dentist, { DentistDTO } from '@Clinic/shared/models/dentist';

@injectable()
export default class DentistsService {
  static diToken = Symbol('dentists-service');

  private urlPrefix = '/dentists';

  async getList() {
    const { data } = await Axios.get<Array<DentistDTO>>(this.urlPrefix);

    return data.map((dto) => new Dentist(dto));
  }
}
