import { createStyles, Theme } from '@material-ui/core/styles';

export default ({ palette: { primary }, spacing, shortcuts }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    rootIconOnly: {
      paddingRight: 0,
      marginRight: spacing(3),
    },
    label: {},
    checkbox: {},
    checkboxChecked: {
      '& + $label': {
        color: primary.main,
      },
    },
    errorText: {
      ...shortcuts.errorText,
      left: 8,
    },
  });
