import MomentUtils from '@date-io/moment';
import moment, { unitOfTime, RelativeTimeKey } from 'moment';

import { formatDate } from './common'; // TODO move to this file

export default class DateUtils extends MomentUtils {
  date(value: string | Date) {
    return moment.utc(value);
  }

  parse(value: string, formatString: string) {
    return moment.utc(value, formatString, true);
  }
}

export const timeToDate = (time: string, utc = true, type?: 'AM' | 'PM') => {
  const init = utc ? moment.utc : moment;
  const args = {
    time: type ? `${time} ${type}` : time,
    format: type ? ['hh:mm A'] : ['HH:mm'],
  };

  return init(args.time, args.format).toISOString();
};

export const dateToTime = (date: string, utc = true, type?: 'AM' | 'PM') => {
  const format = type ? 'hh:mm' : 'HH:mm';

  return formatDate(date, format, utc);
};

export const isAfter = (
  compareToDate: string,
  comparableDate: string,
  compareType?: unitOfTime.StartOf,
  utc?: boolean
) => {
  const init = utc ? moment.utc : moment;

  return init(comparableDate).isAfter(compareToDate, compareType);
};

export const sortDates = <T>(
  dates: Array<T>,
  options?: { direction?: 'asc' | 'desc'; format?: RelativeTimeKey; utc?: boolean }
) => {
  return dates.sort((a, b) => {
    const isUTC = options?.utc ?? true;
    const init = isUTC ? moment.utc : moment;

    const firstDateMilliseconds = init(a, options?.format).valueOf();
    const secondDateMilliseconds = init(b, options?.format).valueOf();

    return (
      (firstDateMilliseconds - secondDateMilliseconds) * (options?.direction === 'desc' ? -1 : 1)
    );
  });
};
