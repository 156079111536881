import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import { Form as BaseForm } from 'mobx-react-form';
import { RouteComponentProps } from 'react-router-dom';

import container from '@Clinic/core/di-container';
import AuthStore from '@Clinic/shared/stores/auth';
import LayoutStore from '@Clinic/shared/stores/layout';
import { LayoutView } from '@Clinic/core/Layout/Layout.constants';
import ROUTES from '@Clinic/shared/constants/routes';
import TextField from '@shared/components/TextField';
import Form from '@shared/components/Form';
import AuthHeading, { AuthHeadingPadding } from '@shared/components/AuthHeading';
import { NotificationType, showNotification } from '@shared/components/Notification';
import { USER_FIELDS } from '@Clinic/shared/constants/user';
import ForgotPasswordForm from './forms/forgot-password';

import styles from './ForgotPassword.styles';

export interface ForgotPasswordProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithWidth {}

@observer
class ForgotPassword extends React.Component<ForgotPasswordProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private form: BaseForm = new ForgotPasswordForm();

  constructor(props: ForgotPasswordProps) {
    super(props);

    this.setPageTitle();
    this.layoutStore.updateConfig({
      navigationProps: {
        backButtonProps: {
          link: ROUTES.public.login,
        },
      },
    });
  }

  componentDidUpdate(prevProps: ForgotPasswordProps) {
    if (prevProps.width !== this.props.width) {
      this.setPageTitle();
    }
  }

  componentWillUnmount() {
    this.layoutStore.setDefaultConfig();
  }

  private setPageTitle = () => {
    this.layoutStore.updateConfig({
      navigationProps: {
        pageDescription: this.layoutStore.view === LayoutView.mobile ? 'Forgot password' : null,
      },
    });
  };

  private handleSubmit = async (data) => {
    const { history } = this.props;

    try {
      await this.authStore.resetPassword(data);
      showNotification(
        'An email link has been sent with instructions on how to reset your password.',
        NotificationType.email
      );
      history.push(ROUTES.public.login);
    } catch (err) {
      throw err;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AuthHeading
          heading="Forgot Password"
          subheading={
            <>
              We will send you a link to reset your password. <br /> Please enter your email address
              below.{' '}
            </>
          }
          padding={AuthHeadingPadding.none}
        />
        <Form
          footerFullWidth
          withControls
          withCancelButton={false}
          formInstance={this.form}
          buttonProps={{
            submit: {
              text: 'Send me a link',
              fullWidth: this.layoutStore.view === LayoutView.mobile,
            },
          }}
          onSubmit={this.handleSubmit}
        >
          <TextField
            label="Email"
            placeholder="Enter email"
            field={this.form.$(USER_FIELDS.email)}
          />
        </Form>
      </div>
    );
  }
}

export default withStyles(styles)(withWidth()(ForgotPassword));
