import { Container } from 'inversify';

import Config from '@core/config';
import HTTPClient from '@core/http-client';
import * as sharedServices from '@Clinic/shared/services';
import * as sharedStores from '@Clinic/shared/stores';

const container = new Container();

const instances = [
  Config,
  HTTPClient,
  ...Object.values(sharedServices),
  ...Object.values(sharedStores),
];

instances.forEach((instance: InstanceType<any>) => {
  container.bind(instance.diToken).to(instance).inSingletonScope();
});

export default container;
