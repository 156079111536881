import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default function styles({ breakpoints, palette: { colors }, spacing }: Theme) {
  return createStyles({
    root: {},
    text: {
      fontSize: 16,

      '& a': {
        color: colors.blue,
        fontWeight: 700,
      },

      '& .heading': {
        fontSize: 18,
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: spacing(4),
      },

      '& .subheading': {
        fontSize: 16,
        fontWeight: 700,
      },

      '& .section': {
        fontSize: 16,
        margin: spacing(4, 0),
      },

      '& li': {
        margin: spacing(4, 0, 0, 6),
      },

      '& .bold-text': {
        fontWeight: 700,
      },

      '& table': {
        width: '100%',
        borderCollapse: 'collapse',
      },

      '& td': {
        border: `1px solid ${colors.black}`,
        width: '33%',
        padding: spacing(2),
        verticalAlign: 'top',
      },
    },
    loading: {
      minHeight: 276,
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      text: {
        '& table': {
          fontSize: 12,
        },
      },
    },
  });
}
