import Axios from 'axios';
import { injectable } from 'inversify';

@injectable()
export default class LegalInfoService {
  static diToken = Symbol('legal-info-service');

  async getPrivacyPolicy() {
    try {
      const httpInstance = Axios.create();
      httpInstance.defaults.baseURL = window.location.origin;

      const {
        data: { content },
      } = await httpInstance.get<{ content: string }>(
        '/public/data/legal-info/privacy-policy.json'
      );

      return content;
    } catch (err) {
      throw err;
    }
  }

  async getAcceptableUsePolicy() {
    try {
      const httpInstance = Axios.create();
      httpInstance.defaults.baseURL = window.location.origin;

      const {
        data: { content },
      } = await httpInstance.get<{ content: string }>(
        '/public/data/legal-info/acceptable-use-policy.json'
      );

      return content;
    } catch (err) {
      throw err;
    }
  }
}
