import container from '@Clinic/core/di-container';
import AuthStore from '@Clinic/shared/stores/auth';
import { UserRole } from '@Clinic/shared/models/system-user';
import ROUTES from '@Clinic/shared/constants/routes';
import { RequestType } from '@Clinic/shared/models/enquiry';

export const getDefaultRoute = () => {
  const authStore = container.get<AuthStore>(AuthStore.diToken);
  const paths = {
    [UserRole.receptionist]: `${ROUTES.private.enquiries}/${RequestType.allocation}`,
    [UserRole.dentist]: `${ROUTES.private.enquiries}/${RequestType.assigned}`,
    [UserRole.patient]: ROUTES.private.questionnaire,
  };

  return paths[authStore.user?.role];
};
