import { EnquiryCategory, EnquiryStatus, RequestType } from '@Clinic/shared/models/enquiry';
import { SortingType } from '@shared/constants/services';

export const ENQUIRY_STATUS_LABELS = {
  [EnquiryStatus.pending]: 'Submitted',
  [EnquiryStatus.assigned]: 'Assigned',
  [EnquiryStatus.clinicianFeedbackProvided]: 'Reviewed',
  [EnquiryStatus.appointmentScheduled]: 'Scheduled',
};

export const ENQUIRY_CATEGORY_LABELS = {
  [EnquiryCategory.dentalProblem]: 'Dental problem',
  [EnquiryCategory.smileImprovements]: 'Smile assessment',
};

export const ENQUIRY_FIELDS = {
  id: 'id',
  patientFullName: 'patientFullName',
  requestDate: 'requestDate',
  availableTimeSlots: {
    name: 'availableTimeSlots',
    fields: {
      date: 'date',
      workingHourType: 'workingHourType',
    },
  },
  clinician: {
    name: 'clinician',
    fields: {
      id: 'id',
      fullName: 'fullName',
      role: 'role',
    },
  },
  category: 'category',
  isUrgent: 'isUrgent',
  enquiryStatus: 'enquiryStatus',
  archivingDate: 'archivingDate',
  appointmentStartTime: 'appointmentStartTime',
  appointmentEndTime: 'appointmentEndTime',
  detailedProblem: 'detailedProblem',
};

export const FEEDBACK_STATUS_FIELD = 'status';

export const ENQUIRY_TYPE_FIELD = 'enquiryType';

export const ENQUIRY_CLINICIAN_FULL_NAME = 'clinicianFullName';

export const ENQUIRY_DENTIST_FULL_NAME = 'dentistFullName';

export const ENQUIRY_AVAILABILITY_FOR_APPOINTMENT = 'availability';

export const ENQUIRY_DELETION_CAUSE = {
  [EnquiryStatus.appointmentDeleted]: 'Deleted',
  [EnquiryStatus.appointmentCompleted]: 'Completed',
};

export const REQUESTS_DEFAULT_ORDER_FIELDS = {
  [RequestType.allocation]: ENQUIRY_AVAILABILITY_FOR_APPOINTMENT,
  [RequestType.withFeedback]: ENQUIRY_TYPE_FIELD,
  [RequestType.assigned]: ENQUIRY_FIELDS.requestDate,
  [RequestType.scheduled]: ENQUIRY_FIELDS.appointmentStartTime,
  [RequestType.archived]: ENQUIRY_FIELDS.archivingDate,
};

export const REQUESTS_DEFAULT_ORDER_TYPE = {
  [RequestType.allocation]: SortingType.asc,
  [RequestType.withFeedback]: SortingType.asc,
  [RequestType.assigned]: SortingType.desc,
  [RequestType.scheduled]: SortingType.desc,
  [RequestType.archived]: SortingType.desc,
};
