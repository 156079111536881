import { generateId } from '@shared/utils/common';
import Dentist from './dentist';

export interface PrimaryProfileFamilyMember extends ProfileFamilyMember {
  email: ProfileDTO['email'];
  phoneNumber: ProfileDTO['phoneNumber'];
}

export enum ProfileGender {
  male = 1,
  female = 2,
  neither = 3,
}

export const ADD_NEW_MEMBER_OPTION: FamilyMemberDTO = {
  id: generateId('number'),
  label: 'Add new family member',
  dateOfBirth: '',
  isFakeMember: true,
  firstName: '',
  lastName: '',
  userEmail: '',
  userPhoneNumber: '',
  gender: 3,
};

export interface ProfileFamilyMember {
  id: number;
  firstName: string;
  lastName: string;
  userEmail: string;
  userPhoneNumber: string;
  dateOfBirth: string;
  gender: ProfileGender;
  isPrimary?: boolean;
  preferredDentist?: Dentist;
}

export interface FamilyMemberDTO extends ProfileFamilyMember {
  isFakeMember: boolean;
  label: string;
}

export interface ProfileDTO {
  email: string;
  phoneNumber: string;
  familyMembers: Array<ProfileFamilyMember>;
}

export default class Profile {
  email: string;
  phoneNumber: string;
  familyMembers: Array<ProfileFamilyMember>;

  constructor(dto: Partial<ProfileDTO>) {
    this.update(dto);
  }

  get asJson(): ProfileDTO {
    return {
      email: this.email,
      phoneNumber: this.phoneNumber,
      familyMembers: this.familyMembers,
    };
  }

  get primaryProfile() {
    return this.familyMembers.find((member) => member.isPrimary);
  }

  update(newData: Partial<ProfileDTO>) {
    const allData = { ...this.asJson, ...newData };
    Object.assign(this, allData);

    return this.asJson;
  }
}
