import { USER_FIELDS } from '@Clinic/shared/constants/user';
import Form from '@core/forms/base';

export default class ForgotPasswordForm extends Form {
  setup() {
    return {
      fields: [
        {
          name: USER_FIELDS.email,
          type: 'text',
          rules: 'required|email',
        },
      ],
    };
  }
}
