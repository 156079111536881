import { injectable } from 'inversify';
import { observable, computed, action } from 'mobx';

import container from '@Clinic/core/di-container';
import UsersService from '@Clinic/shared/services/users';
import Profile from '@Clinic/shared/models/profile';
import { ProfileFamilyMember, PrimaryProfileFamilyMember } from '@Clinic/shared/models/profile';
import Patient from '@Clinic/shared/models/patient';
import { Id } from '@shared/types/common';
import { ListRequestParams, PageInfo } from '@shared/types/services';

@injectable()
export default class UsersStore {
  static diToken = Symbol('users-store');

  @observable private _patients: Array<Patient> = [];
  @observable private _patientsPageInfo: PageInfo;
  @observable private _profile: Profile | undefined;

  @observable loading = {
    list: false,
    item: false,
    profile: false,
  };
  @observable error = {
    itemGet: false,
    profileGet: false,
  };

  @computed get profile() {
    return this._profile;
  }

  @computed get patients() {
    return this._patients;
  }

  @computed get patientsPageInfo() {
    return this._patientsPageInfo;
  }

  private service = container.get<UsersService>(UsersService.diToken);

  @action getPatients = async (params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { items, pageInfo } = await this.service.getPatients(params);

      this._patients = items;
      this._patientsPageInfo = pageInfo;

      this.loading.list = false;

      return {
        items,
        pageInfo,
      };
    } finally {
      this.loading.list = false;
    }
  };

  @action getPatientProfile = async () => {
    this.error.profileGet = false;
    this.loading.profile = true;

    try {
      this._profile = await this.service.getPatientProfile();
    } catch {
      this.error.profileGet = true;
    } finally {
      this.loading.profile = false;
    }
  };

  createPatientProfile = (data: ProfileFamilyMember) => {
    return this.service.createPatientProfile(data);
  };

  editPatientProfile = (data: PrimaryProfileFamilyMember) => {
    return this.service.editPatientProfile(data);
  };

  deletePatientProfile = () => {
    return this.service.deletePatientProfile();
  };

  editPatientProfileFamilyMember = (id: Id, data: ProfileFamilyMember) => {
    return this.service.editPatientProfileFamilyMember(id, data);
  };

  deletePatientProfileFamilyMember = (id: Id) => {
    return this.service.deletePatientProfileFamilyMember(id);
  };

  changePassword(data: { oldPassword: string; newPassword: string }) {
    return this.service.changePassword(data);
  }

  getAppointments = () => {
    return this.service.getAppointments();
  };
}
