import './polyfill';
import 'reflect-metadata';
import 'react-hot-loader';
import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import WebFont from 'webfontloader';

import {
  getClinicFontName,
  getClinicFontFamily,
  isFontFromGoogle,
} from '@shared/utils/clinicDesign';
import { createTheme, ThemeSettingsOptions } from '@core/theme';
import Loading from '@shared/components/Loading';
import history from '@shared/utils/history';
import Notification from '@shared/components/Notification';
import Confirmation from '@shared/components/Confirmation';
import AppContainer from './AppContainer';

import ClinicApp from './apps/Clinic';
import LayoutStore from '@Clinic/shared/stores/layout';
import ClinicStore from '@Clinic/shared/stores/clinic';
import { UserInterfaceConfiguration } from '@Portal/shared/models/clinic';
import container from '@Clinic/core/di-container';
import AuthStore from '@Clinic/shared/stores/auth';
import Config from '@core/config';
import HTTPClient from '@core/http-client';
import { getEnvironment } from '@shared/utils/env';

const authStore = container.get<AuthStore>(AuthStore.diToken);
const config = container.get<Config>(Config.diToken);
const httpClient = container.get<HTTPClient>(HTTPClient.diToken);

config.initialize();

const {
  apiURL: { origin, pathname },
  domain,
} = config.get().clinic;

httpClient.initialize({
  defaults: {
    baseURL: `${origin}${pathname}`,
    headers: getEnvironment() === EnvironmentType.local ? { DevelopmentOrigin: domain } : {},
  },
  getAccessToken: () => authStore.tokens.access,
  refreshToken: authStore.refreshToken,
});
authStore.initialize();

interface AppState {
  showLoader: boolean;
  theme: Theme;
}
class App extends React.Component<{}, AppState> {
  private clinicStore = container.get<ClinicStore>(ClinicStore.diToken);
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private themeSettings: ThemeSettingsOptions = {};

  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      theme: createTheme(),
    };
  }

  updateThemeSettings = (settings: ThemeSettingsOptions) => {
    this.themeSettings = {
      ...this.themeSettings,
      ...settings,
    };
  };

  updateTheme = (themeOptions: ThemeSettingsOptions) => {
    this.setState({
      theme: createTheme(themeOptions),
    });
  };

  updateUiSettings = (uiConfig: UserInterfaceConfiguration) => {
    if (uiConfig.color) {
      this.updateThemeSettings({ mainColor: uiConfig.color });
    }

    if (uiConfig.font) {
      this.updateThemeSettings({ fontFamily: getClinicFontFamily(uiConfig.font) });

      if (isFontFromGoogle(uiConfig.font)) {
        WebFont.load({
          google: {
            families: [getClinicFontName(uiConfig.font)],
          },
        });
      }
    }

    if (uiConfig.logoUrl) {
      this.layoutStore.setLogo(uiConfig.logoUrl);
    }
  };

  componentDidMount() {
    this.clinicStore.getClinic().then((clinic) => {
      if (!clinic) {
        return;
      }

      const { userInterfaceConfiguration = {} } = clinic;

      this.updateUiSettings(userInterfaceConfiguration);
      this.updateTheme(this.themeSettings);

      this.setState({ showLoader: false });
    });
  }

  render() {
    if (this.state.showLoader) {
      return <Loading absolute />;
    }

    return (
      <React.Fragment>
        <Helmet title={this.clinicStore.clinic.name} />
        <Router history={history}>
          <MuiThemeProvider theme={this.state.theme}>
            <CssBaseline />
            <Notification />
            <Confirmation />
            <AppContainer>
              <ClinicApp />
            </AppContainer>
          </MuiThemeProvider>
        </Router>
      </React.Fragment>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
