import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export const POWERED_BY_HEIGHT = {
  desktop: 16,
  mobile: 10,
};

export const POWERED_BY_BOTTOM_GAP = {
  desktop: 12,
  mobile: 6,
};

export default function styles({ breakpoints }: Theme) {
  return createStyles({
    root: {
      overflow: 'hidden',
    },
    mobileLandscapeRestriction: {
      display: 'flex',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 999999999999,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
    },
    poweredBy: {
      position: 'absolute',
      right: 24,
      bottom: 12,
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      poweredBy: {
        bottom: 6,
      },
    },
  });
}
