import * as React from 'react';
import { observer } from 'mobx-react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import cx from 'classnames';

import Flex from '@shared/components/Flex';
import { DentistDTO } from '@Clinic/shared/models/dentist';
import Image from './components/Image';

import styles from './Dentist.styles';

interface Config {
  selected: boolean;
  fullName: string;
  specialty?: string;
  imgSrc?: string;
  id?: DentistDTO['id'];
  onSelect: (id?: DentistDTO['id']) => any;
}

export interface DentistProps extends WithStyles<typeof styles>, Config {}

@observer
class Dentist extends React.Component<DentistProps> {
  private handleSelect = (e) => {
    e.persist();

    const { id } = e.currentTarget.dataset;

    if (this.props.onSelect) {
      this.props.onSelect(id ? Number(id) : undefined);
    }
  };

  render() {
    const { classes, selected, id, fullName, specialty, imgSrc } = this.props;

    return (
      <Flex
        data-id={id}
        wrap="nowrap"
        className={cx(classes.root, { [classes.rootSelected]: selected })}
        onClick={this.handleSelect}
      >
        <Image imgSrc={imgSrc} selected={selected} id={id} />
        <div className={classes.dentistInfoSection}>
          <div className={classes.name}>{fullName}</div>
          {specialty && <div className={classes.category}>{specialty}</div>}
        </div>
      </Flex>
    );
  }
}

export default withStyles(styles)(Dentist);
