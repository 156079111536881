import * as React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';

import container from '@Clinic/core/di-container';
import { LayoutView } from '@Clinic/core/Layout/Layout.constants';
import LegalInfoStore from '@Clinic/shared/stores/legal-info';
import LayoutStore from '@Clinic/shared/stores/layout';
import ROUTES from '@Clinic/shared/constants/routes';
import ErrorHandler from '@shared/components/ErrorHandler';
import Loading from '@shared/components/Loading';
import Button from '@shared/components/Button';
import history from '@shared/utils/history';
import { filterXSS } from '@shared/utils/common';

import styles from './AcceptableUsePolicy.styles';

export interface AcceptableUsePolicyProps extends WithStyles<typeof styles>, WithWidth {}

@observer
class AcceptableUsePolicy extends React.Component<AcceptableUsePolicyProps> {
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private legalInfoStore = container.get<LegalInfoStore>(LegalInfoStore.diToken);

  constructor(props: AcceptableUsePolicyProps) {
    super(props);

    this.initialize();
  }

  private initialize = async () => {
    this.updateLayout();

    await this.legalInfoStore.getAcceptableUsePolicy();
  };

  componentDidUpdate(prevProps: AcceptableUsePolicyProps) {
    if (prevProps.width !== this.props.width) {
      this.updateLayout();
    }
  }

  private updateLayout = () => {
    const { updateConfig, view } = this.layoutStore;

    updateConfig({
      content: {
        width: view === LayoutView.desktop ? '80vw' : '100%',
      },
      navigationProps: {
        backButtonProps: {
          onClick: this.close,
        },
        pageDescription: 'Acceptable Use Policy',
      },
    });
  };

  componentWillUnmount() {
    this.layoutStore.setDefaultConfig();
  }

  private close = () => history.push(ROUTES.public.signUp);

  render() {
    const { classes } = this.props;
    const { error, loading, acceptableUsePolicy } = this.legalInfoStore;

    if (error.acceptableUsePolicy) {
      return <ErrorHandler />;
    }

    if (loading.acceptableUsePolicy) {
      return <Loading classes={{ root: classes.loading }} />;
    }

    const sanitizedHTML = acceptableUsePolicy ? filterXSS(acceptableUsePolicy) : '';

    return (
      <div className={classes.root}>
        <div
          className={classes.text}
          dangerouslySetInnerHTML={{
            __html: sanitizedHTML,
          }}
        />
        {this.layoutStore.view === LayoutView.mobile && (
          <Button fullWidth text="Close" color="secondary" onClick={this.close} />
        )}
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(AcceptableUsePolicy));
