import * as React from 'react';

import { ProfileGender } from '@Clinic/shared/models/profile';
import RadioGroup, { RadioGroupProps } from '@shared/components/RadioGroup';

export interface GenderSelectorProps extends Pick<RadioGroupProps, 'field'> {}

const genders = [
  { id: ProfileGender.male, label: 'Male' },
  { id: ProfileGender.female, label: 'Female' },
  { id: ProfileGender.neither, label: 'Prefer not to say' },
];

class GenderSelector extends React.Component<GenderSelectorProps> {
  render() {
    const { field } = this.props;

    return <RadioGroup options={genders} field={field} label="Gender" />;
  }
}

export default GenderSelector;
