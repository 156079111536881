import { createStyles, Theme } from '@material-ui/core/styles';

import { getHoverStyles } from '@core/theme';
import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default function styles({ spacing, palette: { colors }, breakpoints }: Theme) {
  return createStyles({
    root: {},
    formHeading: {
      fontSize: 24,
      fontWeight: 700,
      color: colors.black,
    },
    firstNameField: {
      marginRight: spacing(4),
      width: '40%',
    },
    firstNameFieldLabel: {},
    lastNameField: {
      width: '60%',
    },
    emailField: {
      width: '100%',
    },
    birthDateField: {
      width: 258,
    },
    phoneNumberField: {
      width: 230,
    },
    passwordField: {
      width: '100%',
    },
    requirements: {
      marginTop: spacing(2),
      fontSize: 12,
      wordBreak: 'break-word',
      color: colors.text,
    },
    termsAndConditionsField: {
      marginTop: spacing(5),
      marginLeft: -spacing(2),

      ...getHoverStyles({
        supportedDeviceStyles: {
          backgroundColor: 'transparent',
        },
      }),
    },
    checkboxErrorText: {},
    legalInfoLink: {
      fontWeight: 700,
      padding: spacing(0, 1),
      color: colors.blue,
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.8,
      },
    },
    submitBtn: {},

    [breakpoints.down(BREAKPOINT.mobile)]: {
      firstNameField: {
        width: '100%',
        marginRight: 0,
      },
      lastNameField: {
        width: '100%',
        marginRight: 0,
      },
      emailField: {
        width: '100%',
      },
      passwordField: {
        width: '100%',
      },
    },
  });
}
