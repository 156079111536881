export interface DentistDTO {
  id: number;
  fullName: string;
  specialty: string;
  image?: string;
  isBlocked?: boolean;
}

export default class Dentist {
  id: number;
  fullName: string;
  specialty: string;
  image?: string;
  isBlocked: boolean;

  constructor(dto: Partial<DentistDTO>) {
    this.update(dto);
  }

  get asJson(): DentistDTO {
    return {
      id: this.id,
      fullName: this.fullName,
      image: this.image,
      specialty: this.specialty,
      isBlocked: this.isBlocked,
    };
  }

  update(newData: Partial<DentistDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
