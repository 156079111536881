import { createStyles, Theme } from '@material-ui/core/styles';

export default ({ spacing, shortcuts }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    label: {
      margin: spacing(5, 0, 2, 0),
    },
    formControlLabel: {
      marginBottom: spacing(2),
    },
    radios: {
      alignItems: 'flex-start',
    },
    errorText: {
      ...shortcuts.errorText,
      bottom: -spacing(3),
    },
  });
