import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  KeyboardDatePicker as MuiDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePickerProps,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormLabel from '@material-ui/core/FormLabel';
import { observer } from 'mobx-react';
import { Field } from 'mobx-react-form';
import * as moment from 'moment';
import cx from 'classnames';

import { getTestAttr } from '@shared/utils/common';
import { getScrollAttr } from '@shared/utils/form';
import DateUtils from '@shared/utils/date';
import Ellipsis from '@shared/components/Ellipsis';

import styles from './DatePicker.styles';

type AllPickersProps = Partial<Omit<KeyboardDateTimePickerProps, 'classes' | 'onChange'>> &
  Partial<Omit<MuiDatePickerProps, 'classes' | 'onChange'>>;

export interface DatePickerProps extends WithStyles<typeof styles>, AllPickersProps {
  computedPlaceholder?: boolean;
  value?: string | null;
  field?: Field;
  format?: string;
  withTimePicker?: boolean;
  testAttributeArgs: {
    module: string;
    elementDescription: string;
  };
  startOfUnit?: moment.unitOfTime.StartOf;
  endOfUnit?: moment.unitOfTime.StartOf;
  onChange?: (value: string) => any;
}

const getPlaceholder = (dateFormat = '') => {
  return dateFormat.replace(/DD/i, '25').replace(/MM/i, '10').replace(/YYYY/i, '1990');
};

@observer
class DatePicker extends React.Component<DatePickerProps> {
  static defaultProps = {
    computedPlaceholder: true,
    format: 'DD/MM/YYYY',
  };

  private validateField = () => {
    const { field } = this.props;

    if (field) {
      field.validate().then(() => {
        if (field.hasError) {
          field.showErrors();
        }
      });
    }
  };

  private getNormalizedDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      return '';
    }

    const { startOfUnit, endOfUnit } = this.props;

    if (startOfUnit) {
      return date.startOf(startOfUnit).toISOString();
    }

    if (endOfUnit) {
      return date.endOf(endOfUnit).toISOString();
    }

    return date.toISOString();
  };

  private handleDateChange = (date: MaterialUiPickersDate, rawValue: string) => {
    const { field, onChange } = this.props;

    if (!date?.isValid()) {
      if (field) {
        field.set('value', rawValue);
      }

      return;
    }

    const normalizedDate = this.getNormalizedDate(date);

    if (field) {
      field.set('value', normalizedDate);
      this.validateField();
    }

    if (onChange) {
      onChange(normalizedDate);
    }
  };

  private handleBlur = (e) => {
    e.persist();

    const { onBlur } = this.props;

    this.validateField();

    if (onBlur) {
      onBlur(e);
    }
  };

  private get value() {
    const { field, value } = this.props;

    return field ? field.value : value;
  }

  render() {
    const {
      field,
      value,
      helperText,
      label,
      withTimePicker,
      error,
      testAttributeArgs,
      classes,
      startOfUnit,
      endOfUnit,
      placeholder,
      computedPlaceholder,
      format,
      onChange,
      onBlur,
      ...otherProps
    } = this.props;

    const commonProps = {
      format,
      inputProps: getTestAttr(
        testAttributeArgs.module,
        `${testAttributeArgs.elementDescription}`,
        'date-picker'
      ),
      value: this.value || null,
      name: field?.name,
      error: Boolean(error || field?.error),
      InputProps: {
        endAdornment: <DateRangeIcon className={classes.icon} />,
        classes: {
          input: classes.input,
        },
      },
      helperText: null,
      placeholder: computedPlaceholder ? getPlaceholder(format) : placeholder,
      ...getScrollAttr(otherProps.name || field?.name),
      onChange: this.handleDateChange,
      onBlur: this.handleBlur,
    };
    const errorText = helperText || field?.error;

    return (
      <div className={cx(classes.root, { [classes.rootDisabled]: otherProps.disabled })}>
        {label && (
          <FormLabel classes={{ root: classes.label }} component="legend">
            {label}
          </FormLabel>
        )}
        <MuiPickersUtilsProvider utils={DateUtils}>
          <div className={cx(classes.innerRoot, { [classes.innerRootError]: commonProps.error })}>
            {withTimePicker ? (
              <KeyboardDateTimePicker
                autoOk
                clearable
                variant="inline"
                {...commonProps}
                {...otherProps}
              />
            ) : (
              <MuiDatePicker
                autoOk
                disablePast
                disableToolbar
                variant="inline"
                {...commonProps}
                {...otherProps}
              />
            )}
          </div>
        </MuiPickersUtilsProvider>
        {commonProps.error && <Ellipsis classes={{ root: classes.errorText }} text={errorText} />}
      </div>
    );
  }
}

export default withStyles(styles)(DatePicker);
