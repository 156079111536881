import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';
import merge from 'lodash/merge';

import EnquiriesService from '@Clinic/shared/services/enquiries';
import {
  AvailableTimeSlot,
  QuestionnaireAnswer,
} from '@Clinic/modules/Private/submodules/Questionnaire/Questionnaire.types';
import container from '@Clinic/core/di-container';
import Enquiry, { EnquiryCategory, RequestType } from '@Clinic/shared/models/enquiry';
import { EnquiryFeedbackDTO } from '@Clinic/shared/models/enquiry/feedback';
import { ListRequestParams, PageInfo } from '@shared/types/services';
import { Id } from '@shared/types/common';
import {
  REQUESTS_DEFAULT_ORDER_FIELDS,
  REQUESTS_DEFAULT_ORDER_TYPE,
} from '@Clinic/shared/constants/enquiry';
import { TreatmentDTO } from '../models/enquiry/treatment';

@injectable()
export default class EnquiriesStore {
  static diToken = Symbol('enquiries-store');

  private service = container.get<EnquiriesService>(EnquiriesService.diToken);

  @observable private _list: Array<Enquiry> = [];
  @observable private _pageInfo: PageInfo;
  @observable private _terms = '';
  @observable private _treatments: Array<TreatmentDTO> = [];

  @observable loading = {
    list: false,
    terms: false,
    item: false,
    feedback: false,
  };

  @observable error = {
    feedbackGET: false,
    enquiryGET: false,
  };

  @computed get pageInfo() {
    return this._pageInfo;
  }

  @computed get list() {
    return this._list;
  }

  @computed get terms() {
    return this._terms;
  }

  @computed get treatments() {
    return this._treatments;
  }

  @action getList = async (
    metadata: { requestType: RequestType; category?: EnquiryCategory },
    params?: ListRequestParams
  ) => {
    this.loading.list = true;

    const { requestType } = metadata;

    const defaultParams: Partial<ListRequestParams> = {
      sorting: {
        field: REQUESTS_DEFAULT_ORDER_FIELDS[requestType],
        type: REQUESTS_DEFAULT_ORDER_TYPE[requestType],
      },
      pagination: { page: 0, pageSize: 10 },
      searchKeyword: '',
    };

    const finalParams = merge(defaultParams, params);

    try {
      const { items, pageInfo } = await this.service.getEnquiries(metadata, finalParams);

      this._list = items;
      this._pageInfo = pageInfo;

      return {
        items,
        pageInfo,
      };
    } finally {
      this.loading.list = false;
    }
  };

  getEnquiryQuestions = () => {
    return this.service.getEnquiryQuestions();
  };

  getEnquiryTerms = async () => {
    this.loading.terms = true;

    try {
      this._terms = await this.service.getEnquiryTerms();
    } finally {
      this.loading.terms = false;
    }
  };

  getEnquiry = async (id: Id) => {
    this.loading.item = true;
    this.error.enquiryGET = false;

    try {
      const enquiryDetails = await this.service.getEnquiry(id);

      return enquiryDetails;
    } catch (err) {
      this.error.enquiryGET = true;
      throw err;
    } finally {
      this.loading.item = false;
    }
  };

  getEnquiryFeedback = async (id: Id) => {
    this.loading.feedback = true;
    this.error.feedbackGET = false;

    try {
      const feedback = await this.service.getEnquiryFeedback(id);

      return feedback;
    } catch (err) {
      this.error.feedbackGET = true;
      throw err;
    } finally {
      this.loading.feedback = false;
    }
  };

  provideFeedback = (id: Id, data: EnquiryFeedbackDTO) => {
    return this.service.provideFeedback(id, data);
  };

  createEnquiry = (data: {
    answers: Array<QuestionnaireAnswer>;
    availableTimeSlots: Array<AvailableTimeSlot>;
    familyMemberId: Id;
  }) => {
    return this.service.createEnquiry(data);
  };

  deleteEnquiry = (requestType: RequestType, enquiryId: Id) => {
    return this.service.deleteEnquiry(enquiryId);
  };

  archiveEnquiry = (requestType: RequestType, enquiryId: Id) => {
    return this.service.archiveEnquiry(enquiryId);
  };

  assignEnquiryToDentist = (requestType: RequestType, enquiryId: Id, dentistId: Id) => {
    return this.service.assignEnquiryToDentist(enquiryId, dentistId);
  };

  assignEnquiryToReceptionist = (enquiryId: Id) => {
    return this.service.assignEnquiryToReceptionist(enquiryId);
  };

  confirmClinician = (requestType: RequestType, enquiryId: Id, clinicianId: Id) => {
    return this.service.confirmClinician(enquiryId, clinicianId);
  };

  uploadFile = (file: File) => {
    return this.service.uploadFile(file);
  };

  @action resetList = () => {
    return (this._list = []);
  };

  getTreatments = async () => {
    this._treatments = await this.service.getTreatments();
  };

  getNotes = async (enquiryId: Id) => {
    return await this.service.getNotes(enquiryId);
  };

  addNote = async (enquiryId: Id, params) => {
    await this.service.addNote(enquiryId, params);
  };

  editNote = async (enquiryId: Id, noteId: Id, params) => {
    await this.service.editNote(enquiryId, noteId, params);
  };
}
