import Axios from 'axios';
import { injectable } from 'inversify';
import Enquiry, { EnquirySlotsDTO } from '@Clinic/shared/models/enquiry';
import { ReceptionistAppointment } from '@Clinic/shared/models/appointment';
import Receptionist, { ReceptionistDTO } from '@Clinic/shared/models/receptionist';

@injectable()
export default class ReceptionistsService {
  static diToken = Symbol('receptionist-service');

  private urlPrefix = '/receptionists';

  private getURL(url: string | number) {
    return `${this.urlPrefix}/${url}`;
  }

  async getPatientTimeSlots(id: Enquiry['id']) {
    const { data } = await Axios.get<EnquirySlotsDTO>(this.getURL(`enquiries/${id}`));

    return data;
  }

  async getCurrentReceptionist() {
    const { data } = await Axios.get<ReceptionistDTO>(this.getURL('current'));

    return new Receptionist(data);
  }

  makeReceptionistAppointment(data: ReceptionistAppointment) {
    return Axios.post(`/enquiries/${data.enquiryId}/appointments`, data.time);
  }
}
