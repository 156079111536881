import { observable, action, computed } from 'mobx';
import { injectable } from 'inversify';

import container from '@Clinic/core/di-container';
import LegalInfoService from '@Clinic/shared/services/legal-info';

@injectable()
export default class LegalInfoStore {
  static diToken = Symbol('legal-info-service');
  private service = container.get<LegalInfoService>(LegalInfoService.diToken);
  @observable private _acceptableUsePolicy: string | undefined;
  @observable private _privacyPolicy: string | undefined;
  @observable loading = {
    acceptableUsePolicy: false,
    privacyPolicy: false,
  };
  @observable error = {
    acceptableUsePolicy: false,
    privacyPolicy: false,
  };

  @computed get acceptableUsePolicy() {
    return this._acceptableUsePolicy;
  }

  @action getAcceptableUsePolicy = async () => {
    this.loading.acceptableUsePolicy = true;

    try {
      this._acceptableUsePolicy = await this.service.getAcceptableUsePolicy();
    } catch {
      this.error.acceptableUsePolicy = true;
    } finally {
      this.loading.acceptableUsePolicy = false;
    }
  };

  @computed get privacyPolicy() {
    return this._privacyPolicy;
  }

  @action getPrivacyPolicy = async () => {
    this.loading.privacyPolicy = true;

    try {
      this._privacyPolicy = await this.service.getPrivacyPolicy();
    } catch {
      this.error.privacyPolicy = true;
    } finally {
      this.loading.privacyPolicy = false;
    }
  };
}
