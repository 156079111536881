import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing }: Theme) {
  return createStyles({
    root: {},
    questionHeading: {
      marginBottom: spacing(6),
      fontWeight: 700,
      fontSize: 24,
    },
    dentist: {
      marginBottom: spacing(2),

      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  });
}
