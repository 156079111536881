import { createStyles, Theme } from '@material-ui/core';

export default ({ spacing, palette: { colors } }: Theme) =>
  createStyles({
    root: {
      color: colors.grey,
      fontSize: 10,
      fontWeight: 500,
    },
    brandName: {
      color: colors.purple,
      margin: spacing(0, 1),
    },
    brandLogo: {
      fontSize: 12,
    },
  });
