export interface ReceptionistDTO {
  id: number;
  fullName: string;
}

export default class Receptionist {
  id: number;
  fullName: string;

  constructor(dto: Partial<ReceptionistDTO>) {
    this.update(dto);
  }

  get asJson(): ReceptionistDTO {
    return {
      id: this.id,
      fullName: this.fullName,
    };
  }

  update(newData: Partial<ReceptionistDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
