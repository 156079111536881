import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import { DentistDTO } from '@Clinic/shared/models/dentist';
import DentistIcon from '../../../../../../../../../shared/icons/Dentist';
import UnknownDentistIcon from './icons/UnknownDentist';
import CheckboxIcon from './icons/Checkbox';

import styles from './Image.styles';

export interface ImageProps extends WithStyles<typeof styles> {
  selected: boolean;
  imgSrc?: string;
  id?: DentistDTO['id'];
}

const Image: React.FC<ImageProps> = ({ classes, imgSrc, selected, id }) => {
  const image = React.useMemo(() => {
    const config = [
      {
        condition: selected,
        image: <CheckboxIcon classes={{ root: classes.root }} />,
      },
      {
        condition: !id,
        image: <UnknownDentistIcon classes={{ root: classes.root }} />,
      },
      {
        condition: id && !imgSrc,
        image: <DentistIcon classes={{ root: classes.root }} />,
      },
      {
        condition: imgSrc,
        image: <img className={classes.root} src={imgSrc} />,
      },
    ];

    return config.find(({ condition }) => condition)?.image;
  }, [imgSrc, selected]);

  return image ?? null;
};

export default withStyles(styles)(Image);
