import React from 'react';

import FormController from 'mobx-react-form';
import { observer } from 'mobx-react';

import { FormLabel, WithStyles, withStyles } from '@material-ui/core';

import PhoneInput from 'react-phone-input-2';

import { USER_FIELDS } from '@Clinic/shared/constants/user';
import Ellipsis from '../Ellipsis';

import styles from './PhoneNumberInput.styles';
import 'react-phone-input-2/lib/style.css';

type Props = WithStyles<typeof styles> & {
  form: FormController;
};

const PhoneNumberInputComponent = ({ form, classes }: Props) => {
  const setPhoneNumber = (value: string) => {
    const formattedValue = value ? `+${value}` : '';
    form.$(USER_FIELDS.phoneNumber).set(formattedValue);
  };

  const hasError = Boolean(form.$(USER_FIELDS.phoneNumber).error);

  return (
    <>
      <FormLabel classes={{ root: classes.legend }} component="legend">
        Phone number
      </FormLabel>
      <PhoneInput
        placeholder="+7440 961500"
        country={'gb'}
        value={form.$(USER_FIELDS.phoneNumber).value}
        inputClass={classes.input}
        dropdownClass={classes.dropdown}
        onChange={setPhoneNumber}
        isValid={!hasError}
        inputProps={{
          required: true,
        }}
      />
      {hasError && (
        <Ellipsis
          classes={{ root: classes.errorText }}
          text={form.$(USER_FIELDS.phoneNumber)?.error}
        />
      )}
    </>
  );
};

export const PhoneNumberInput = withStyles(styles)(observer(PhoneNumberInputComponent));
