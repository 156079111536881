import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper';
import { observer } from 'mobx-react';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import container from '@Clinic/core/di-container';
import LayoutStore from '@Clinic/shared/stores/layout';
import AuthStore from '@Clinic/shared/stores/auth';
import { UserRole } from '@Clinic/shared/models/system-user';
import ROUTES from '@Clinic/shared/constants/routes';
import { RequestType } from '@Clinic/shared/models/enquiry';
import { getLayoutView, setCSSScreenHeightVariable } from './Layout.utils';
import { LayoutView } from './Layout.constants';
import Navigation from './components/Navigation';
import Header, { HeaderLink, HeaderProps } from './components/Header';

import styles from './Layout.styles';

export interface LayoutProps extends WithStyles<typeof styles>, WithWidth {}

@observer
class Layout extends React.Component<LayoutProps> {
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private authStore = container.get<AuthStore>(AuthStore.diToken);

  constructor(props: LayoutProps) {
    super(props);

    this.setCSSScreenHeightVariable();
    this.setView();
  }

  componentDidUpdate(prevProps: LayoutProps) {
    if (this.props.width !== prevProps.width) {
      this.setView();
    }
  }

  private setCSSScreenHeightVariable = () => {
    setCSSScreenHeightVariable();
    window.addEventListener('resize', debounce(setCSSScreenHeightVariable, 10));
  };

  private setView = () => {
    const layoutView = getLayoutView(this.props.width);
    const { setView } = this.layoutStore;

    setView(layoutView);
  };

  private handleLogout = () => {
    this.authStore.logout(true);
  };

  private get classView() {
    const { classes } = this.props;

    return {
      [LayoutView.desktop]: {
        root: classes.rootDesktop,
        content: classes.contentDesktop,
        module: classes.moduleDesktop,
      },
      [LayoutView.mobile]: {
        root: classes.rootMobile,
        content: classes.contentMobile,
        module: classes.moduleMobile,
      },
    };
  }

  private get headerLinksUserRole(): Array<HeaderLink> {
    const { classes } = this.props;
    const { user } = this.authStore;

    const headerLinks: Array<HeaderLink & { roles: Array<UserRole> }> = [
      {
        linkProps: {
          to: `${ROUTES.private.enquiries}/${RequestType.allocation}`,
        },
        id: 'New',
        text: 'New',
        roles: [UserRole.receptionist],
      },
      {
        linkProps: {
          to: `${ROUTES.private.enquiries}/${RequestType.assigned}`,
        },
        id: 'Assigned',
        text: 'Assigned',
        roles: [UserRole.receptionist],
      },
      {
        linkProps: {
          to: `${ROUTES.private.enquiries}/${RequestType.withFeedback}`,
        },
        id: 'Reviewed',
        text: 'Reviewed',
        roles: [UserRole.receptionist],
      },
      {
        linkProps: {
          to: `${ROUTES.private.enquiries}/${RequestType.assigned}`,
        },
        id: 'New Queries',
        text: 'New',
        roles: [UserRole.dentist],
      },
      {
        linkProps: {
          to: `${ROUTES.private.enquiries}/${RequestType.withFeedback}`,
        },
        id: 'Reviewed Queries',
        text: 'Reviewed',
        roles: [UserRole.dentist],
      },
      {
        linkProps: {
          to: `${ROUTES.private.enquiries}/${RequestType.scheduled}`,
        },
        id: 'Scheduled',
        text: 'Scheduled',
        roles: [UserRole.receptionist, UserRole.dentist],
      },
      {
        linkProps: {
          to: `${ROUTES.private.enquiries}/${RequestType.archived}`,
        },
        id: 'Archived',
        text: 'Archived',
        roles: [UserRole.receptionist, UserRole.dentist],
      },
      {
        linkProps: {
          to: ROUTES.private.patients,
        },
        id: 'Patient directory',
        text: 'Patient directory',
        roles: [UserRole.receptionist],
        className: classes.patientsDirectoryLink,
      },
    ];

    return headerLinks
      .filter(({ roles }) => roles.includes(user?.role))
      .map(({ roles, ...otherProps }) => otherProps);
  }

  private getClassUserRole = (
    role?: UserRole
  ): { root: string; content: string; module: string } | undefined => {
    const { classes } = this.props;
    const { loggedIn } = this.authStore;

    if (!loggedIn || !role) {
      return {
        root: classes.rootNotAuthenticated,
        content: classes.contentNotAuthenticated,
        module: classes.moduleNotAuthenticated,
      };
    }

    const classUserRole = {
      [UserRole.patient]: {
        root: classes.rootPatient,
        content: classes.contentPatient,
        module: classes.modulePatient,
      },
    };

    return (
      classUserRole[role] || {
        root: classes.rootOtherRoles,
        content: classes.contentOtherRoles,
        module: classes.moduleOtherRoles,
      }
    );
  };

  private get headerConfig() {
    const { user } = this.authStore;

    const config: Array<{ condition: boolean; headerConfig: Partial<HeaderProps> }> = [
      {
        condition: [UserRole.receptionist, UserRole.dentist].some((role) => role === user?.role),
        headerConfig: {
          linksTitle: 'Queries',
        },
      },
    ];

    return config.find(({ condition }) => condition)?.headerConfig;
  }

  private renderHeader = () => {
    const { user } = this.authStore;
    const { logo, config } = this.layoutStore;
    const { classes } = this.props;

    return (
      <Header
        withLogout={user?.role !== UserRole.patient}
        withProfile={user?.role === UserRole.patient}
        classes={{
          root: classes.header,
        }}
        logo={{
          src: logo,
        }}
        links={this.headerLinksUserRole}
        profile={{
          route: ROUTES.private.profile,
        }}
        onLogoutClick={this.handleLogout}
        {...this.headerConfig}
        {...config?.headerProps}
      />
    );
  };

  private renderNavigation = () => {
    const { view, config } = this.layoutStore;
    const { classes } = this.props;

    return (
      <Navigation
        layoutView={view}
        classes={{ root: classes.navigation }}
        {...config?.navigationProps}
      />
    );
  };

  private renderAboveModuleSection = () => {
    const { user } = this.authStore;
    const { config } = this.layoutStore;
    const headerUpperSectionRoles = [UserRole.dentist, UserRole.receptionist];

    if (headerUpperSectionRoles.includes(user?.role) || config?.showHeader) {
      return this.renderHeader();
    }

    if (config?.navigationProps) {
      return this.renderNavigation();
    }

    return null;
  };

  render() {
    const { view, config, moduleRef } = this.layoutStore;
    const { user } = this.authStore;
    const { classes, children } = this.props;

    return (
      <div
        className={cx(
          classes.root,
          this.classView[view].root,
          this.getClassUserRole(user?.role)?.root
        )}
      >
        <div
          style={{ width: config?.content?.width }}
          className={cx(
            classes.content,
            this.classView[view].content,
            this.getClassUserRole(user?.role)?.content,
            {
              [classes.contentDesktopFixedHeight]:
                view === LayoutView.desktop && config?.moduleFixedHeight,
            }
          )}
        >
          {this.renderAboveModuleSection()}
          <Paper
            ref={moduleRef}
            square={view === LayoutView.mobile}
            className={cx(
              classes.module,
              this.classView[view].module,
              this.getClassUserRole(user?.role)?.module,
              config?.classes?.module
            )}
          >
            {children}
          </Paper>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withWidth()(Layout));
