import { createStyles, Theme } from '@material-ui/core/styles';

import { spacing as moduleSpacing } from '@core/theme';
import { PADDING } from './Layout.constants';
import { getScreenHeightSource } from './Layout.utils';
import { POWERED_BY_BOTTOM_GAP, POWERED_BY_HEIGHT } from '@Clinic/Clinic.styles';

const HEIGHT = {
  header: 48,
  navigation: 38,
};

const GAP = {
  aboveModuleSection: moduleSpacing * 3,
  desktopContent: moduleSpacing * 4,
  poweredBySection: moduleSpacing * 3,
};

const poweredBySize = {
  desktop: GAP.poweredBySection + POWERED_BY_HEIGHT.desktop + POWERED_BY_BOTTOM_GAP.desktop,
  mobile: GAP.poweredBySection + POWERED_BY_HEIGHT.mobile + POWERED_BY_BOTTOM_GAP.mobile,
};

const getModuleHeight = (extraContentHeight: number = 0) => {
  return `calc(${getScreenHeightSource()} - ${extraContentHeight}px)`;
};

const getDesktopContentHeightProperties = (property: 'height' | 'maxHeight') => {
  return {
    '& $moduleDesktop': {
      [property]: getModuleHeight(GAP.desktopContent + poweredBySize.desktop),
    },

    '& $navigation + $moduleDesktop': {
      [property]: getModuleHeight(
        HEIGHT.navigation + GAP.aboveModuleSection + GAP.desktopContent + poweredBySize.desktop
      ),
    },

    '& $header + $moduleDesktop': {
      [property]: getModuleHeight(
        HEIGHT.header + GAP.aboveModuleSection + GAP.desktopContent + poweredBySize.desktop
      ),
    },
  };
};

export default function styles({ palette: { colors }, boxShadow, spacing, radius }: Theme) {
  return createStyles({
    root: {
      overflow: 'hidden',
      minHeight: getScreenHeightSource(),
      background: `linear-gradient(to right, ${colors.greyLight3}, ${colors.white})`,
    },
    rootDesktop: {
      height: getScreenHeightSource(),

      '&$rootPatient, &$rootNotAuthenticated': {
        display: 'flex',
        justifyContent: 'center',
      },

      '&$rootNotAuthenticated': {
        alignItems: 'center',
      },
    },
    rootMobile: {
      '&$rootPatient, &$rootNotAuthenticated': {
        background: colors.white,
      },
    },
    rootNotAuthenticated: {},
    rootPatient: {},
    rootOtherRoles: {},

    content: {
      padding: 0,
    },
    contentDesktop: {
      paddingTop: GAP.desktopContent,

      '&$contentNotAuthenticated': {
        width: 400,
        contentDesktop: 0,
      },

      '&$contentPatient': {
        width: 525,
      },

      '&$contentOtherRoles': {},

      '& $navigation, & $header': {
        marginBottom: GAP.aboveModuleSection,
      },

      ...getDesktopContentHeightProperties('maxHeight'),
    },
    contentDesktopFixedHeight: {
      ...getDesktopContentHeightProperties('height'),
    },
    contentMobile: {
      overflow: 'hidden',

      '& $header': {
        paddingRight: spacing(5),
        paddingLeft: spacing(5),
      },

      '& $navigation': {
        padding: spacing(0, 2),
      },

      '& $navigation + $moduleMobile': {
        height: getModuleHeight(HEIGHT.navigation + poweredBySize.mobile),
      },

      '& $header + $moduleMobile': {
        height: getModuleHeight(HEIGHT.header + poweredBySize.mobile),
      },
    },
    contentNotAuthenticated: {},
    contentPatient: {},
    contentOtherRoles: {
      margin: spacing(0, 4),
    },

    navigation: {
      height: HEIGHT.navigation,
    },
    header: {
      height: HEIGHT.header,
    },
    module: {
      overflow: 'auto',
      position: 'relative',
    },
    moduleDesktop: {
      '&$moduleNotAuthenticated': {
        boxShadow: boxShadow.variant2,
        padding: spacing(8),
      },

      '&$modulePatient': {
        boxShadow: boxShadow.variant2,
        padding: spacing(6),
      },
    },
    moduleMobile: {
      boxShadow: 'none',
      padding: spacing(5, `${PADDING.content.mobile.horizontal}px`, 8),
      height: getModuleHeight(poweredBySize.mobile),
    },
    moduleNotAuthenticated: {},
    modulePatient: {},
    moduleOtherRoles: {
      boxShadow: boxShadow.variant7,
      padding: 0,
    },
    patientsDirectoryLink: {
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: -5,
        width: 2,
        height: '100%',
        borderRadius: radius.containers.primary,
        backgroundColor: colors.greyLight2,
      },
    },
  });
}
