import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default function styles({ breakpoints }: Theme) {
  return createStyles({
    root: {},
    passwordField: {
      width: '88%',
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      },
      passwordField: {
        width: '100%',
      },
    },
  });
}
