import * as React from 'react';
import withStyles, { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { NavLink, Link, NavLinkProps } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import cx from 'classnames';

import { Id } from '@shared/types/common';
import Flex from '@shared/components/Flex';
import Button from '@shared/components/Button';

import styles from './Header.styles';

export interface HeaderLink {
  linkProps: NavLinkProps;
  text: React.ReactNode;
  id: Id;
  className?: string;
}

export interface HeaderProps extends WithStyles<typeof styles> {
  withLogout: boolean;
  withProfile: boolean;
  logo: {
    src: string;
  };
  profile: {
    route: string;
  };
  withLogo?: boolean;
  centeredLogo?: boolean;
  linksTitle?: string;
  links?: Array<HeaderLink>;
  onLogoutClick: () => any;
}

const elementImitationStyles: CSSProperties = {
  width: 50,
};

const Header: React.FC<HeaderProps> = (props) => {
  const {
    classes,
    centeredLogo,
    withLogo = true,
    logo,
    linksTitle,
    links,
    profile,
    withLogout,
    withProfile,
    onLogoutClick,
  } = props;
  const justify: GridProps['justify'] = withLogo ? 'space-between' : 'flex-end';

  return (
    <Grid container justify={justify} wrap="nowrap" alignItems="center" className={classes.root}>
      {centeredLogo && <span style={elementImitationStyles} />}
      {withLogo && <img className={classes.logo} src={logo.src} />}

      {Boolean(links?.length) && (
        <Flex alignItems="center" wrap="nowrap" className={classes.links}>
          {linksTitle && <span className={classes.linksTitle}>{linksTitle}:</span>}
          {links?.map(({ linkProps, text, id, className }) => (
            <NavLink
              className={cx(classes.link, className)}
              activeClassName={classes.activeLink}
              {...linkProps}
              key={id}
            >
              {text}
            </NavLink>
          ))}
        </Flex>
      )}

      <Flex>
        {withProfile && (
          <Link to={profile.route}>
            <Button variant="text">
              <AccountCircleIcon className={classes.icon} />
              Profile
            </Button>
          </Link>
        )}
        {withLogout && (
          <Button className={classes.logoutBtn} variant="text" onClick={onLogoutClick}>
            <ExitToAppIcon className={cx(classes.icon, classes.logoutIcon)} />
            Logout
          </Button>
        )}
      </Flex>
    </Grid>
  );
};

export default withStyles(styles)(Header);
