import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import MuiCheckbox, { CheckboxProps as BaseCheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import { Field } from 'mobx-react-form';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import cx from 'classnames';

import { getScrollAttr } from '@shared/utils/form';
import CheckboxIcon, { CheckboxIconType } from '@shared/icons/Checkbox';
import Ellipsis from '@shared/components/Ellipsis';

import styles from './Checkbox.styles';

export interface CheckboxProps
  extends WithStyles<typeof styles>,
    Omit<FormControlLabelProps, 'classes' | 'control' | 'label' | 'onChange'> {
  label?: React.ReactNode;
  field?: Field;
  inputProps?: BaseCheckboxProps['inputProps'] & { [k: string]: string | number | boolean };
  hasError?: boolean;
  errorText?: string;
  onChange?: (checked: boolean, e?) => any;
}

@observer
class Checkbox extends React.Component<CheckboxProps> {
  private handleChange = (e, checked: boolean) => {
    const { field, onChange } = this.props;

    if (field) {
      field.set('value', checked);
    }

    if (onChange) {
      onChange(checked, e);
    }
  };

  @computed private get errorText() {
    const { errorText, field } = this.props;

    return errorText || field?.error;
  }

  @computed private get hasError() {
    const { hasError, field } = this.props;

    return hasError || Boolean(field?.error);
  }

  render() {
    const {
      field,
      checked,
      classes,
      label = null,
      onChange,
      inputProps,
      ...otherProps
    } = this.props;

    return (
      <FormControlLabel
        {...otherProps}
        label={
          <>
            {label}
            {this.hasError && (
              <Ellipsis classes={{ root: classes.errorText }} text={this.errorText} />
            )}
          </>
        }
        classes={{
          root: cx(classes.root, { [classes.rootIconOnly]: !label }),
          label: classes.label,
        }}
        control={
          <MuiCheckbox
            disableRipple
            inputProps={inputProps}            
            classes={{
              root: classes.checkbox,
              checked: classes.checkboxChecked,
            }}
            {...getScrollAttr(field?.name || otherProps.name)}
            checked={field ? field.value : checked}
            icon={<CheckboxIcon iconType={CheckboxIconType.unchecked} />}
            checkedIcon={<CheckboxIcon iconType={CheckboxIconType.checked} />}
            onChange={this.handleChange}
          />
        }
      />
    );
  }
}

export default withStyles(styles)(Checkbox);
