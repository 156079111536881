import { injectable } from 'inversify';
import { observable } from 'mobx';

import container from '@Clinic/core/di-container';
import ReceptionistsService from '@Clinic/shared/services/receptionists';
import ReceptionistsStore from '@Clinic/shared/stores/enquiries';
import Enquiry, { RequestType } from '@Clinic/shared/models/enquiry';
import { ReceptionistAppointment } from '@Clinic/shared/models/appointment';
import Receptionist from '@Clinic/shared/models/receptionist';

@injectable()
export default class ReceptionistStore {
  static diToken = Symbol('receptionist-store');

  private service = container.get<ReceptionistsService>(ReceptionistsService.diToken);
  private enquiriesStore = container.get<ReceptionistsStore>(ReceptionistsStore.diToken);

  @observable _currentReceptionist: Receptionist | undefined;

  @observable loading = {
    timeSlots: false,
    currentReceptionist: false,
  };

  @observable error = {
    timeSlots: false,
    currentReceptionist: false,
  };

  get currentReceptionist() {
    return this._currentReceptionist;
  }

  getPatientTimeSlots = async (id: Enquiry['id']) => {
    this.loading.timeSlots = true;

    try {
      const response = await this.service.getPatientTimeSlots(id);

      return response;
    } catch (e) {
      this.error.timeSlots = true;
      throw e;
    } finally {
      this.loading.timeSlots = false;
    }
  };

  getCurrentReceptionist = async () => {
    this.loading.currentReceptionist = true;

    try {
      this._currentReceptionist = await this.service.getCurrentReceptionist();
    } catch (e) {
      this.error.currentReceptionist = true;
      throw e;
    } finally {
      this.loading.currentReceptionist = false;
    }
  };

  makeReceptionistAppointment = async (data: ReceptionistAppointment, requestType: RequestType) => {
    await this.service.makeReceptionistAppointment(data);
    this.enquiriesStore.getList({ requestType });
  };
}
