import { createStyles, Theme } from '@material-ui/core';

export default ({ radius }: Theme) =>
  createStyles({
    root: {
      minWidth: 60,
      width: 60,
      height: 60,
      objectFit: 'cover',
      borderRadius: radius.containers.primary,
    },
  });
