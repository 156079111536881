import { injectable } from 'inversify';
import { observable, action, computed } from 'mobx';

import ClinicService from '@Clinic/shared/services/clinic';
import container from '@Clinic/core/di-container';
import Clinic from '@Portal/shared/models/clinic';

@injectable()
export default class ClinicStore {
  static diToken = Symbol('clinic-store');
  private service = container.get<ClinicService>(ClinicService.diToken);

  @observable private _clinic: Clinic;

  @observable loading = false;
  @observable error = {
    clinicGet: false,
  };

  @computed get clinic() {
    return this._clinic;
  }

  @action getClinic = async () => {
    this.loading = true;
    this.error.clinicGet = false;

    try {
      this._clinic = await this.service.getClinic();

      return this._clinic;
    } catch {
      this.error.clinicGet = true;
    } finally {
      this.loading = false;
    }
  };
}
