import { USER_FIELDS } from '@Clinic/shared/constants/user';
import Form from '@core/forms/base';
import getPluginsConfig, { Validations } from '@core/forms/plugins';

export const personalDetailsFields = {
  password: 'password',
  newPasswordConfirm: 'newPasswordConfirm',
  isAcceptableUsePolicyAccepted: 'isAcceptableUsePolicyAccepted',
};

const validation: Validations = {
  acceptableUsePolicy: {
    function(value: boolean) {
      return value;
    },
    message: 'You must agree to our Acceptable Use Policy and Privacy Policy',
  },
};

export default class PersonalDetailsForm extends Form {
  setup() {
    return {
      fields: [
        {
          name: USER_FIELDS.firstName,
          type: 'text',
          rules: 'required',
        },
        {
          name: USER_FIELDS.lastName,
          type: 'text',
          rules: 'required',
        },
        {
          name: USER_FIELDS.email,
          type: 'text',
          rules: 'required|email',
        },
        {
          name: USER_FIELDS.dateOfBirth,
          type: 'text',
          rules: 'required|date|patient_birth_date',
        },
        {
          name: USER_FIELDS.gender,
          type: 'radio',
          rules: 'required',
        },
        {
          name: USER_FIELDS.phoneNumber,
          type: 'text',
          rules: 'required|phoneNumber',
        },
        {
          name: personalDetailsFields.password,
          type: 'password',
          rules: 'required|passwordStrength',
        },
        {
          name: personalDetailsFields.newPasswordConfirm,
          type: 'password',
          rules: `required|samePass:${personalDetailsFields.password}`,
        },
        {
          name: personalDetailsFields.isAcceptableUsePolicyAccepted,
          value: false,
          rules: 'acceptableUsePolicy',
          options: {
            validateOnChange: true,
          },
        },
      ],
    };
  }

  plugins() {
    return getPluginsConfig(validation);
  }
}
