import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './Checkbox.styles';

export enum CheckboxIconType {
  checked,
  unchecked,
}

interface CheckboxIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {
  iconType: CheckboxIconType;
}

const icon = {
  [CheckboxIconType.checked]: (
    <>
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H12C13.933 0.5 15.5 2.067 15.5 4V12C15.5 13.933 13.933 15.5 12 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M12 6L7 11L4 8"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  [CheckboxIconType.unchecked]: (
    <>
      <g filter="url(#filter0_i)">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
          fill="#F6F6F6"
        />
      </g>
      <path
        color="white"
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H12C13.933 0.5 15.5 2.067 15.5 4V12C15.5 13.933 13.933 15.5 12 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z"
        stroke="#E0E0E0"
      />
      <defs>
        <filter
          id="filter0_i"
          x="0"
          y="0"
          width="16"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </>
  ),
};

const CheckboxIcon: React.FC<CheckboxIconProps> = ({ iconType, ...otherProps }) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" color="primary" {...otherProps}>
      {icon[iconType]}
    </SvgIcon>
  );
};

export default withStyles(styles)(CheckboxIcon);
