import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './CheckboxIcon.styles';

interface CheckboxIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const CheckboxIcon: React.FC<CheckboxIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      classes={{ root: classes.root }}
    >
      <rect
        x="1"
        y="1"
        width="58"
        height="58"
        rx="7"
        fill="white"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M19 30L26.5 38L43 22"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(CheckboxIcon);
