import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import Logo from '@shared/components/Logo';

import styles from './PoweredBy.styles';

export interface PoweredByProps extends WithStyles<typeof styles> {}

const PoweredBy: React.FC<PoweredByProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      Powered by
      <span className={classes.brandName}>PocketDentist</span>
      <Logo size="small" classes={{ root: classes.brandLogo }} />
    </div>
  );
};

export default withStyles(styles)(PoweredBy);
