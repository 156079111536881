import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form as BaseForm } from 'mobx-react-form';
import { Link } from 'react-router-dom';

import container from '@Clinic/core/di-container';
import AuthStore from '@Clinic/shared/stores/auth';
import LoginForm from './forms/login';
import TextField from '@shared/components/TextField';
import Form from '@shared/components/Form';
import AuthFormFooter from '@Clinic/modules/Public/components/AuthFormFooter';
import Flex from '@shared/components/Flex';
import Button from '@shared/components/Button';
import LockIcon from '@shared/icons/Lock';
import { USER_FIELDS } from '@Clinic/shared/constants/user';
import ROUTES from '@Clinic/shared/constants/routes';
import LayoutStore from '@Clinic/shared/stores/layout';

import styles from './Login.styles';

export interface LoginProps extends WithStyles<typeof styles> {}

@observer
class Login extends React.Component<LoginProps> {
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private form: BaseForm = new LoginForm();
  @observable private submitting = false;

  private login = async (data) => {
    this.submitting = true;

    try {
      await this.authStore.login(data);
    } finally {
      this.submitting = false;
    }
  };

  render() {
    const { classes } = this.props;
    const { logo } = this.layoutStore;

    return (
      <div className={classes.root}>
        <div className={classes.logoWrapper}>
          <img className={classes.logo} src={logo} alt="" />
        </div>
        <Form withControls={false} formInstance={this.form} onSubmit={this.login}>
          <TextField
            label="Email"
            placeholder="Enter email"
            field={this.form.$(USER_FIELDS.username)}
          />
          <TextField
            withPasswordToggle
            label="Enter password"
            placeholder="Password"
            field={this.form.$(USER_FIELDS.password)}
          />
          <AuthFormFooter justify="flex-end">
            <Flex alignItems="center" classes={{ root: classes.forgotPasswordLinkWrapper }}>
              <LockIcon />
              <Link to={ROUTES.public.forgotPassword} className={classes.forgotPasswordLink}>
                Forgot password?
              </Link>
            </Flex>
            <Button fullWidth type="submit" text="Log in" loading={this.submitting} />
            <div className={classes.divider}> or </div>
            <Link to={ROUTES.public.signUp} className={classes.signUpLink}>
              <Button fullWidth text="Sign Up" />
            </Link>
          </AuthFormFooter>
        </Form>
      </div>
    );
  }
}

export default withStyles(styles)(Login);
