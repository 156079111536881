import * as React from 'react';
import { RouteComponentProps, Switch, Route, RouteProps, Redirect } from 'react-router-dom';

import Login from './submodules/Login';
import ForgotPassword from './submodules/ForgotPassword';
import NewPassword from './submodules/NewPassword';
import AccountActivate, { AccountActivateType } from './submodules/AccountActivate';
import SignUp from './submodules/SignUp';
import AcceptableUsePolicy from './submodules/AcceptableUsePolicy';
import PrivacyPolicy from './submodules/PrivacyPolicy';
import Lazyload from '@shared/components/LazyLoad';
import ROUTES from '@Clinic/shared/constants/routes';
export interface PublicProps extends RouteComponentProps {}

class Public extends React.Component<PublicProps> {
  get routes(): Array<RouteProps> {
    const {
      login,
      forgotPassword,
      newPassword,
      signUp,
      account,
      acceptableUsePolicy,
      privacyPolicy,
    } = ROUTES.public;

    return [
      { path: login, component: Login },
      { path: forgotPassword, component: ForgotPassword },
      { path: newPassword, component: NewPassword },
      {
        path: `${account}/:type(${AccountActivateType.activation}|${AccountActivateType.confirmation})`,
        component: AccountActivate,
      },
      { path: signUp, component: SignUp },
      { path: acceptableUsePolicy, component: AcceptableUsePolicy },
      { path: privacyPolicy, component: PrivacyPolicy },
    ];
  }

  render() {
    return (
      <Lazyload>
        <Switch>
          {this.routes.map((routeProps) => (
            <Route key={String(routeProps.path)} {...routeProps} />
          ))}
          <Redirect to={ROUTES.public.login} />
        </Switch>
      </Lazyload>
    );
  }
}

export default Public;
