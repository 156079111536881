import { createStyles, Theme, fade } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { getHoverStyles } from '@core/theme';

export default function styles({
  spacing,
  palette: { colors, primary },
  radius,
  transitions,
}: Theme) {
  const activeStyles: CSSProperties = {
    backgroundColor: fade(primary.main, 0.16),
    borderColor: primary.main,
  };

  return createStyles({
    root: {
      padding: spacing(2),
      cursor: 'pointer',
      border: '2px solid',
      borderColor: 'transparent',
      borderRadius: radius.containers.primary,
      transition: `${transitions.duration.standard}s`,
      overflow: 'hidden',

      ...getHoverStyles({
        supportedDeviceStyles: {
          ...activeStyles,
        },
      }),
    },
    rootSelected: {
      ...activeStyles,
    },
    dentistInfoSection: {
      marginLeft: spacing(4),
      alignSelf: 'center',
    },
    name: {
      fontSize: 24,
      fontWeight: 700,
    },
    category: {
      fontSize: 16,
      color: colors.grey,
    },
  });
}
