import { WorkingHourType } from '@Portal/shared/models/clinic';
import { ProfileFamilyMember } from '../profile';
import { QuestionDTO } from '@Clinic/shared/models/enquiry/question';
import { UserRole } from '@Clinic/shared/models/system-user';
import { ClinicianDTO } from '@Clinic/shared/models/clinitian';
import { Id } from '@shared/types/common';

export enum RequestType { // TODO merge with EnquiryType
  allocation = 'allocation',
  withFeedback = 'with-feedback',
  assigned = 'assigned',
  scheduled = 'scheduled',
  archived = 'archived',
}

export enum EnquiryCategory {
  dentalProblem = 1,
  smileImprovements = 2,
}

export enum EnquiryStatus {
  pending = 1,
  assigned = 2,
  clinicianFeedbackProvided = 3,
  appointmentScheduled = 4,
  appointmentCompleted = 5,
  appointmentDeleted = 6,
  appointmentTimeSent = 7,
}

export enum EnquiryPositiveNegativeCategory {
  none = 0,
  positive = 1,
  negative = 2,
}

export interface EnquirySlotDTO {
  date: string;
  workingHourType: WorkingHourType;
}

export interface EnquirySlotsDTO {
  enquiryId: EnquiryDTO['id'];
  patientPreferredTimeSlots: Array<EnquirySlotDTO>;
}

interface EnquiryAppointment {
  date: string;
  workingHourType: WorkingHourType;
}

interface EnquiryClinician {
  id: ClinicianDTO['id'];
  fullName: string;
  role: UserRole;
}

export interface EnquiryDetails {
  enquiryCategory: EnquiryCategory;
  patientFamilyMember: ProfileFamilyMember;
  questions: Array<QuestionDTO>;
  requestDate: string;
  detailedProblem?: string;
  clinician?: {
    id: ClinicianDTO['id'];
    fullName: string;
    role: UserRole;
  };
  internalNotes: Array<InternalNote>;
}

export interface EnquiryDTO {
  id: number;
  patientFullName: string;
  requestDate: string;
  archivingDate: string;
  availableTimeSlots: Array<EnquiryAppointment>;
  appointmentStartTime: string;
  appointmentEndTime: string;
  clinician: EnquiryClinician;
  category: EnquiryCategory;
  enquiryStatus: EnquiryStatus;
  isUrgent: boolean;
  detailedProblem: string;
}

export interface InternalNote {
  id: Id;
  author: EnquiryClinician;
  comment: string;
  created: string;
}

export default class Enquiry {
  id: number;
  patientFullName: string;
  requestDate: string;
  archivingDate: string;
  availableTimeSlots: Array<EnquiryAppointment>;
  appointmentStartTime: string;
  appointmentEndTime: string;
  clinician: EnquiryClinician;
  category: EnquiryCategory;
  enquiryStatus: EnquiryStatus;
  isUrgent: boolean;
  detailedProblem: string;

  constructor(dto: Partial<EnquiryDTO>) {
    this.update(dto);
  }

  get asJson(): EnquiryDTO {
    return {
      id: this.id,
      patientFullName: this.patientFullName,
      requestDate: this.requestDate,
      archivingDate: this.archivingDate,
      availableTimeSlots: this.availableTimeSlots,
      appointmentStartTime: this.appointmentStartTime,
      appointmentEndTime: this.appointmentEndTime,
      clinician: this.clinician,
      category: this.category,
      enquiryStatus: this.enquiryStatus,
      isUrgent: this.isUrgent,
      detailedProblem: this.detailedProblem,
    };
  }

  update(newData: Partial<EnquiryDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
