import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown, WithWidth } from '@material-ui/core/withWidth';
import FormController from 'mobx-react-form';
import { observer } from 'mobx-react';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';
import {
  USER_FIELDS,
  PATIENT_MINIMUM_ACCEPTABLE_BIRTH_DATE,
  PATIENT_MIN_AGE,
} from '@Clinic/shared/constants/user';
import ROUTES from '@Clinic/shared/constants/routes';
import PasswordRequirements from '@Clinic/shared/components/PasswordRequirements';
import GenderSelector from '@Clinic/shared/components/GenderSelector';
import TextField from '@shared/components/TextField';
import Form from '@shared/components/Form';
import DatePicker from '@shared/components/DatePicker';
import Flex from '@shared/components/Flex';
import Checkbox from '@shared/components/Checkbox';
import { PhoneNumberInput } from '@shared/components/PhoneNumberInput';

import { personalDetailsFields } from '../../forms/personal-details';

import styles from './PersonalDetails.styles';

export interface PersonalDetailsProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithWidth {
  form: FormController;
  onSubmit: () => any;
}

@observer
class PersonalDetails extends React.Component<PersonalDetailsProps> {
  public get content() {
    const { width, form, classes } = this.props;
    const ageDisclaimer = `You must be ${PATIENT_MIN_AGE} years of age or above to register`;

    return (
      <>
        <Flex wrap={isWidthDown(BREAKPOINT.mobile, width) ? 'wrap' : 'nowrap'}>
          <TextField
            label="First name"
            placeholder="Enter first name"
            field={form.$(USER_FIELDS.firstName)}
            classes={{ root: classes.firstNameField }}
          />
          <TextField
            label="Last name"
            placeholder="Enter last name"
            field={form.$(USER_FIELDS.lastName)}
            classes={{ root: classes.lastNameField }}
          />
        </Flex>
        <TextField
          label="Email"
          placeholder="Enter email"
          field={form.$(USER_FIELDS.email)}
          classes={{ root: classes.emailField }}
        />
        <DatePicker
          startOfUnit="day"
          disableToolbar={false}
          disablePast={false}
          maxDate={PATIENT_MINIMUM_ACCEPTABLE_BIRTH_DATE}
          label="Date of birth"
          field={form.$(USER_FIELDS.dateOfBirth)}
          testAttributeArgs={{ module: 'Sign-up', elementDescription: 'birth-date' }}
          classes={{ root: classes.birthDateField }}
        />
        <div className={classes.requirements}>{ageDisclaimer}</div>
        <GenderSelector field={form.$(USER_FIELDS.gender)} />

        <PhoneNumberInput form={form} />
        <TextField
          label="Password"
          placeholder="Enter password"
          field={form.$(personalDetailsFields.password)}
          classes={{ root: classes.passwordField }}
        />
        <PasswordRequirements />
        <TextField
          label="Confirm password"
          placeholder="Confirm password"
          field={form.$(personalDetailsFields.newPasswordConfirm)}
          classes={{ root: classes.passwordField }}
        />
        <Checkbox
          label={
            <>
              I agree with the
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={ROUTES.public.acceptableUsePolicy}
                className={classes.legalInfoLink}
              >
                Acceptable Use Policy
              </a>
              and
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={ROUTES.public.privacyPolicy}
                className={classes.legalInfoLink}
              >
                Privacy Policy
              </a>
            </>
          }
          field={form.$(personalDetailsFields.isAcceptableUsePolicyAccepted)}
          classes={{ root: classes.termsAndConditionsField }}
        />
      </>
    );
  }

  render() {
    const { classes, onSubmit } = this.props;

    return (
      <div className={classes.root}>
        <Form
          footerFullWidth
          withCancelButton={false}
          formInstance={this.props.form}
          buttonProps={{
            submit: {
              text: 'Next',
              classes: {
                root: classes.submitBtn,
              },
            },
          }}
          onSubmit={onSubmit}
        >
          <h6 className={classes.formHeading}>Enter personal details</h6>
          {this.content}
        </Form>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(PersonalDetails));
