import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './PasswordRequirements.styles';

export interface PasswordRequirementsProps extends WithStyles<typeof styles> {}

const passwordRequirements =
  'Your password must contain at least 8 characters & contain at least one character from three of the following categories: Uppercase letter (A-Z), Lowercase letter (a-z), Digit (0-9), Special character (~`!@#$%^&()+=_-{}[]|:;“’?/<>,.).*';

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({ classes }) => (
  <p className={classes.root}>{passwordRequirements}</p>
);

export default withStyles(styles)(PasswordRequirements);
