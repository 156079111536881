import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, palette: { colors } }: Theme) {
  return createStyles({
    root: {},
    text: {
      fontSize: 16,

      '& a': {
        color: colors.blue,
        fontWeight: 700,
      },

      '& .heading': {
        fontSize: 18,
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: spacing(4),
      },

      '& .subheading': {
        fontSize: 16,
        fontWeight: 700,
      },

      '& .section': {
        margin: spacing(4, 0),
      },

      '& li': {
        margin: spacing(4, 0, 0, 6),
      },

      '& .bold-text': {
        fontWeight: 700,
      },
    },
    loading: {
      minHeight: 276,
    },
  });
}
