export enum ClinicFont {
  Lato = 1,
  Roboto = 2,
  OpenSans = 3,
  NotoSans = 4,
  Ubuntu = 5,
  Arial = 6,
}

export const CLINIC_FONT_NAME = {
  [ClinicFont.Lato]: 'Lato',
  [ClinicFont.Roboto]: 'Roboto',
  [ClinicFont.OpenSans]: 'Open Sans',
  [ClinicFont.NotoSans]: 'Noto Sans',
  [ClinicFont.Ubuntu]: 'Ubuntu',
  [ClinicFont.Arial]: 'Arial',
};

export const fontsFromGoogle = new Set([
  ClinicFont.Lato,
  ClinicFont.Roboto,
  ClinicFont.OpenSans,
  ClinicFont.NotoSans,
  ClinicFont.Ubuntu,
]);

export const FALLBACK_CLINIC_FONT_NAMES = 'Helvetica, sans-serif';
