import Axios from 'axios';
import { injectable } from 'inversify';

import Question, {
  QuestionCategory,
  QuestionDTO,
  QuestionType,
} from '@Clinic/shared/models/enquiry/question';
import Enquiry, {
  EnquiryDTO,
  EnquiryStatus,
  EnquiryPositiveNegativeCategory,
  RequestType,
  EnquiryDetails,
  EnquiryCategory,
  InternalNote,
} from '@Clinic/shared/models/enquiry';
import { ListRequestParams, ListResponse } from '@shared/types/services';
import { REQUEST_PARAMS } from '@shared/constants/services';
import { ENQUIRY_FIELDS } from '@Clinic/shared/constants/enquiry';
import {
  AvailableTimeSlot,
  FileId,
  QuestionnaireAnswer,
} from '@Clinic/modules/Private/submodules/Questionnaire/Questionnaire.types';
import { Id } from '@shared/types/common';
import { DentistDTO } from '@Clinic/shared/models/dentist';
import EnquiryFeedback, { EnquiryFeedbackDTO } from '@Clinic/shared/models/enquiry/feedback';
import { ProfileFamilyMember } from '@Clinic/shared/models/profile';
import { UserRole } from '@Clinic/shared/models/system-user';
import { TreatmentDTO } from '../models/enquiry/treatment';

export interface EnquiryDetailsType {
  positiveAnswers?: Array<string>;
  negativeAnswers?: Array<string>;
  jawAnswer?: {
    value: string;
    category: QuestionCategory.dentalProblemJaw | null;
  };
  teethAnswer?: {
    value: string;
    category:
      | QuestionCategory.dentalProblemUpperTeeth
      | QuestionCategory.dentalProblemLowerTeeth
      | null;
  };
  images?: Array<string>;
  requestDate: string;
  detailedProblem?: string;
  enquiryCategory: EnquiryCategory;
  clinician?: {
    id: DentistDTO['id'];
    fullName: string;
    role: UserRole;
  };
  patientFamilyMember: ProfileFamilyMember;
  appointmentEndTime?: string;
  appointmentStartTime?: string;
  internalNotes: Array<InternalNote>;
}

@injectable()
export default class EnquiriesService {
  static diToken = Symbol('enquiries-service');

  private urlPrefix = '/enquiries';

  async uploadFile(file: File): Promise<FileId> {
    const formData = new FormData();

    formData.append('File', file);

    const { data } = await Axios.put('/enquiry-images', formData);

    return data;
  }

  async getEnquiryTerms() {
    const { data } = await Axios.get<string>(this.getURL('terms'));

    return data;
  }

  async createEnquiry(data: {
    answers: Array<QuestionnaireAnswer>;
    availableTimeSlots: Array<AvailableTimeSlot>;
    familyMemberId: Id;
  }) {
    const normalizedData = {
      familyMemberId: data.familyMemberId,
      answers: data.answers.map(({ questionId, value }) => ({
        questionId,
        value: JSON.stringify(value),
      })),
      availableTimeSlots: data.availableTimeSlots.map(({ date, workingHourType }) => ({
        date,
        workingHourType,
      })),
    };

    return Axios.post(this.urlPrefix, normalizedData);
  }

  async getEnquiryQuestions() {
    const { data } = await Axios.get<Array<QuestionDTO>>(this.getURL('questions'));

    return data.map((dto) => new Question(dto));
  }

  private getEnquiriesQuery(
    { requestType, category }: { requestType: RequestType; category?: EnquiryCategory },
    { pagination, sorting, searchKeyword }: ListRequestParams
  ) {
    const statuses = {
      [RequestType.assigned]: [EnquiryStatus[EnquiryStatus.assigned]],
      [RequestType.withFeedback]: [EnquiryStatus[EnquiryStatus.clinicianFeedbackProvided]],
      [RequestType.allocation]: [EnquiryStatus[EnquiryStatus.pending]],
      [RequestType.scheduled]: [EnquiryStatus[EnquiryStatus.appointmentScheduled]],
    };

    const isArchived = requestType === RequestType.archived;

    return `
      {
        enquiries(
          ${REQUEST_PARAMS.pagination.fields.page}: ${pagination.page}, 
          ${REQUEST_PARAMS.pagination.fields.pageSize}: ${pagination.pageSize}, 
          ${REQUEST_PARAMS.searchKeyword}: "${searchKeyword}", 
          ${REQUEST_PARAMS.sorting.name}: { 
            ${REQUEST_PARAMS.sorting.fields.type}: ${sorting?.type}, 
            ${REQUEST_PARAMS.sorting.fields.field}: ${sorting?.field} 
          },   
          ${
            statuses[requestType]
              ? `statuses: ${JSON.stringify(statuses[requestType]).replace(/"/g, '')},`
              : ''
          }
          isArchived: ${isArchived},
          ${category ? `categories: ${EnquiryCategory[category]}` : ''}
        ) {
          ${REQUEST_PARAMS.items} {
            ${ENQUIRY_FIELDS.id},
            ${ENQUIRY_FIELDS.detailedProblem},
            ${ENQUIRY_FIELDS.requestDate},
            ${ENQUIRY_FIELDS.clinician.name} {
                ${ENQUIRY_FIELDS.clinician.fields.id},
                ${ENQUIRY_FIELDS.clinician.fields.fullName},
                ${ENQUIRY_FIELDS.clinician.fields.role},
            },
            ${ENQUIRY_FIELDS.patientFullName},
            ${ENQUIRY_FIELDS.availableTimeSlots.name} {
              ${ENQUIRY_FIELDS.availableTimeSlots.fields.date},
              ${ENQUIRY_FIELDS.availableTimeSlots.fields.workingHourType}
            },
            ${ENQUIRY_FIELDS.category},
            ${ENQUIRY_FIELDS.appointmentStartTime},
            ${ENQUIRY_FIELDS.appointmentEndTime},
            ${ENQUIRY_FIELDS.enquiryStatus}
            ${ENQUIRY_FIELDS.isUrgent}
            ${ENQUIRY_FIELDS.archivingDate}
          },
          ${REQUEST_PARAMS.pageInfo.name} {
            ${REQUEST_PARAMS.pageInfo.fields.totalCount},
            ${REQUEST_PARAMS.pageInfo.fields.hasPreviousPage},
            ${REQUEST_PARAMS.pageInfo.fields.hasNextPage}
          }
        }
      }
    `;
  }

  async getEnquiries(
    metadata: { requestType: RequestType; category?: EnquiryCategory },
    params: ListRequestParams
  ) {
    const { data } = await Axios.get<{ enquiries: ListResponse<EnquiryDTO> }>(this.urlPrefix, {
      params: { query: this.getEnquiriesQuery(metadata, params) },
    });

    const {
      enquiries: { items, pageInfo },
    } = data;

    return {
      pageInfo,
      items: items.map((dto) => new Enquiry(dto)),
    };
  }

  async getEnquiry(id: Id): Promise<EnquiryDetailsType> {
    const { data } = await Axios.get<EnquiryDetails>(this.getURL(id));

    const response: EnquiryDetailsType = {
      ...data,
      positiveAnswers: [],
      negativeAnswers: [],
      jawAnswer: {
        value: '',
        category: null,
      },
      teethAnswer: {
        value: '',
        category: null,
      },
      images: [],
    };

    if (data?.questions) {
      const sortedQuestions: Array<QuestionDTO> = [];
      const firstQuestion = data.questions.find(
        ({ category }) => category === QuestionCategory.general
      );

      const setNextQuestion = (questionId: Question['id']) => {
        const nextQuestion = data.questions.find((question) => question.id === questionId);

        if (nextQuestion) {
          sortedQuestions.push(nextQuestion);
        }

        const nextQuestionId =
          nextQuestion?.nextQuestionId || nextQuestion?.answers[0].nextQuestionId;

        if (nextQuestionId) {
          setNextQuestion(nextQuestionId);
        }
      };

      if (firstQuestion) {
        setNextQuestion(firstQuestion.id);
      }

      if (data?.questions.length !== sortedQuestions.length) {
        const missingQuestions: Array<QuestionDTO> = data.questions.filter((dataQuestion) => {
          const hasQuestion = sortedQuestions.some((sortedQuestion) => {
            return dataQuestion.id === sortedQuestion.id;
          });

          return !hasQuestion;
        });

        sortedQuestions.push(...missingQuestions);
      }

      const filteredQuestions = sortedQuestions.map((question): QuestionDTO => {
        return {
          ...question,
          answers: question.answers.filter(({ text }) => text !== '<b>Additional comments:</b> ""'),
        };
      });

      // tslint:disable-next-line:cyclomatic-complexity
      filteredQuestions.reduce((acc, question) => {
        const { type, category, answers } = question;

        // For positive answers
        if (
          (type === QuestionType.text ||
            type === QuestionType.singleChoice ||
            type === QuestionType.multiChoice) &&
          (category === QuestionCategory.dentalProblem ||
            category === QuestionCategory.smileImprovements ||
            category === QuestionCategory.virtualConsultation)
        ) {
          answers.map((answer) => {
            if (answer.positiveNegativeCategory === EnquiryPositiveNegativeCategory.positive) {
              acc.positiveAnswers = [...acc.positiveAnswers, answer.text];
            }
          });
        }

        // For negative answers
        if (
          (type === QuestionType.singleChoice || type === QuestionType.multiChoice) &&
          category === QuestionCategory.dentalProblem
        ) {
          answers.map((answer) => {
            if (answer.positiveNegativeCategory === EnquiryPositiveNegativeCategory.negative) {
              acc.negativeAnswers = [...acc.negativeAnswers, answer.text];
            }
          });
        }

        // For teeth diagram
        if (
          type === QuestionType.singleChoice &&
          (category === QuestionCategory.dentalProblemLowerTeeth ||
            category === QuestionCategory.dentalProblemUpperTeeth)
        ) {
          acc.teethAnswer = {
            value: question.answers[0].text,
            category:
              category === QuestionCategory.dentalProblemUpperTeeth
                ? QuestionCategory.dentalProblemUpperTeeth
                : QuestionCategory.dentalProblemLowerTeeth,
          };
        }

        // Fot jaw diagram
        if (type === QuestionType.singleChoice && category === QuestionCategory.dentalProblemJaw) {
          acc.jawAnswer = {
            value: answers[0].text,
            category: QuestionCategory.dentalProblemJaw,
          };
        }

        return response;
      }, response);

      filteredQuestions.forEach((question: QuestionDTO) => {
        const { type, answers } = question;

        // For images
        if (type === QuestionType.image && answers.length) {
          answers.map((el) => {
            response.images = [...response.images, el.text];
          });
        }
      });
    }

    return response;
  }

  async getEnquiryFeedback(id: Id) {
    const { data } = await Axios.get<EnquiryFeedbackDTO | undefined>(
      `${this.urlPrefix}/${id}/feedback`
    );

    if (data) {
      return new EnquiryFeedback(data);
    }
  }

  provideFeedback(id: Id, data: EnquiryFeedbackDTO) {
    const url = `${this.urlPrefix}/${id}/feedback`;

    return Axios.post(url, data);
  }

  deleteEnquiry(enquiryId: Id) {
    return Axios.delete(this.getURL(enquiryId));
  }

  archiveEnquiry(enquiryId: Id) {
    return Axios.post(this.getURL(`${enquiryId}/archive`));
  }

  assignEnquiryToDentist(enquiryId: Id, dentistId: Id) {
    return Axios.patch(this.getURL(`${enquiryId}/dentist`), { dentistId });
  }

  assignEnquiryToReceptionist(enquiryId: Id) {
    return Axios.patch(this.getURL(`${enquiryId}/assign-to-myself`));
  }

  confirmClinician(enquiryId: Id, clinicianId: Id) {
    return Axios.patch(this.getURL(`${enquiryId}/dentist/confirm`), { dentistId: clinicianId });
  }

  private getURL(url: string | number) {
    return `${this.urlPrefix}/${url}`;
  }

  async getTreatments() {
    const { data } = await Axios.get<Array<TreatmentDTO>>('/treatments');

    return data;
  }

  async getNotes(enquiryId: Id) {
    const { data } = await Axios.get(this.getURL(`${enquiryId}/notes`));
    return data;
  }

  async addNote(enquiryId: Id, params) {
    const { data } = await Axios.post(this.getURL(`${enquiryId}/notes`), params);
    return data;
  }

  async editNote(enquiryId: Id, noteId: Id, params) {
    await Axios.patch(this.getURL(`${enquiryId}/notes/${noteId}`), params);
  }
}
