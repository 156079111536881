import { createStyles, Theme } from '@material-ui/core';

export default ({ spacing, palette: { colors, primary }, shortcuts, radius }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    rootDisabled: {
      ...shortcuts.disabled,
    },
    innerRoot: {
      '& .MuiInputBase-root': {
        padding: 1,
      },
    },
    innerRootError: {
      '& .MuiInputBase-root': {
        padding: 0,
        border: `2px solid ${colors.error}`,
        borderRadius: radius.containers.primary,
        backgroundColor: colors.redLight,
      },
    },
    label: {
      margin: spacing(5, 0, 1),
      color: colors.text,
      fontSize: 12,
    },
    input: {
      paddingTop: 0,
      paddingBottom: 0,
      borderTopLeftRadius: radius.containers.primary,
      borderBottomLeftRadius: radius.containers.primary,
    },
    icon: {
      margin: spacing(0, 2),
      color: primary.main,
      cursor: 'pointer',
    },
    errorText: {
      ...shortcuts.errorText,
      bottom: -14,
    },
  });
