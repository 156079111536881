import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT, PADDING } from '@Clinic/core/Layout/Layout.constants';

export default function styles({ spacing, palette: { colors }, breakpoints }: Theme) {
  const signUpBtnMarginTop = spacing(3);
  const signUpBtnHeight = 48;

  return createStyles({
    root: {
      position: 'relative',
      minHeight: '100%',
    },
    dentistSelect: {},
    signUpBtn: {
      marginTop: signUpBtnMarginTop,
      height: signUpBtnHeight,
    },
    dentistSelectBtn: {},
    messageRoot: {
      '& svg': {
        '& path': {
          fill: colors.greyLight2,
        },
      },
    },
    messageHeading: {
      color: colors.black,
      fontSize: 24,
      fontWeight: 700,
    },
    messageSubheading: {
      fontSize: 16,
      color: colors.greyDark,
    },
    messageButton: {
      marginTop: spacing(6),
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      dentistSelect: {
        paddingBottom: signUpBtnHeight + signUpBtnMarginTop,
      },
      dentistSelectBtn: {
        position: 'absolute',
        bottom: 0,
      },
      messageRoot: {
        marginTop: '40%',

        '& svg': {
          fontSize: 60,
        },
      },
      messageButton: {
        width: `calc(100% - ${PADDING.content.mobile.horizontal * 2}px)`,
        position: 'absolute',
        left: 0,
        marginLeft: PADDING.content.mobile.horizontal,
        bottom: 32,
      },
    },
  });
}
