import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './UnknownDentistIcon.styles';

interface UnknownDentistIconProps
  extends Omit<SvgIconProps, 'classes'>,
    WithStyles<typeof styles> {}

const UnknownDentistIcon: React.FC<UnknownDentistIconProps> = ({ classes }) => {
  return (
    <SvgIcon width="60" height="60" viewBox="0 0 60 60" classes={{ root: classes.root }}>
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H52C56.4183 0 60 3.58172 60 8V52C60 56.4183 56.4183 60 52 60H8C3.58172 60 0 56.4183 0 52V8Z"
        fill="#F6F6F6"
      />
      <path
        d="M19.5703 22.0156H23.5781C23.8359 18.6641 26.0859 16.5781 29.625 16.5781C33.1172 16.5781 35.3672 18.7109 35.3672 21.6172C35.3672 24.125 34.3125 25.6016 31.5938 27.2656C28.3828 29.1875 26.8828 31.2969 26.9062 34.3906V36.5469H31.0078V35.0234C31.0078 32.5625 31.8516 31.25 34.8281 29.4922C37.8281 27.6875 39.7266 25.0859 39.7266 21.4062C39.7266 16.5781 35.6953 12.9219 29.7891 12.9219C23.2031 12.9219 19.8281 16.9531 19.5703 22.0156ZM29.2734 47.4219C31.1719 47.4219 32.5078 46.0625 32.5078 44.1641C32.5078 42.2422 31.1719 40.8828 29.2734 40.8828C27.375 40.8828 26.0156 42.2422 26.0156 44.1641C26.0156 46.0625 27.375 47.4219 29.2734 47.4219Z"
        fill="#A9A9A9"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(UnknownDentistIcon);
